.ProjectResultCard {
  width: 224px;
  box-sizing: border-box;
  background-color: #232732;
  position: relative;
  border-radius: 6px;

  &.COMPARE {
    >.content {
      >.key-value {
        height: 40px;
        display: flex;
        align-items: center;
        >.value {
          text-align: center;
          margin: auto;
        }
      }
    }
    >.cad-img {
      position: relative;
      cursor: pointer;
      &:hover {
        .go-to-icon {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 40px;
          color: #eee;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .viewer_icon_div {
          position: absolute;       
          top: 190px;   
          left: 144px;             
          display: flex;
          justify-content: center;
          align-items: center;  
          width: 32px;
          height: 44px;
          >.tooltip {          
            align-items: flex-end;
          }
          .viewer_icon {
            position: absolute;       
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0px;
            // right: 22.32%;
            top: 0px;   
            min-width: 32px;
            width: 32px;
            height: 24px;
            background: linear-gradient(78.41deg, #66DFD9 32.83%, #95E4B3 66.61%);
            border-radius: 6px;        
            color: #232732;          
          }
        }
        .download_icon_div {
          position: absolute;       
          display: flex;
          justify-content: center;
          align-items: center;
          left: 184px;
          // right: 22.32%;
          top: 190px;  
          width: 32px;
          height: 44px;  
          >.tooltip {         
            align-items: flex-end;
          }
          .download_icon {
            position: absolute;       
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0px;
            // right: 22.32%;
            top: 0px;        
            min-width: 32px;
            width: 30px;
            height: 24px;       
            background: #383B45;
            border-radius: 6px;
            color: #CCCCCC;
          }
        }
      }
      .download_icon {
        display: none;
      }
      .viewer_icon {
        display: none;
      }
    }
  }
  &.NORMAL {
    &:hover {
      background-color: #273945;
      cursor: pointer;
      >.cad-img {
        position: relative;
      }
  
      .go-to-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 40px;
        color: #eee;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }      
      .viewer_icon_div {
        position: absolute;       
        top: 190px;   
        left: 144px;             
        display: flex;
        justify-content: center;
        align-items: center;  
        width: 32px;
        height: 44px;
        >.tooltip {          
          align-items: flex-end;
        }
        .viewer_icon {
          position: absolute;       
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0px;
          // right: 22.32%;
          top: 0px;   
          min-width: 32px;
          width: 32px;
          height: 24px;
          background: linear-gradient(78.41deg, #66DFD9 32.83%, #95E4B3 66.61%);
          border-radius: 6px;        
          color: #232732;          
        }
      }
      .download_icon_div {
        position: absolute;       
        display: flex;
        justify-content: center;
        align-items: center;
        left: 184px;
        // right: 22.32%;
        top: 190px;  
        width: 32px;
        height: 44px;  
        >.tooltip {         
          align-items: flex-end;
        }
        .download_icon {
          position: absolute;       
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0px;
          // right: 22.32%;
          top: 0px;        
          min-width: 32px;
          width: 30px;
          height: 24px;       
          background: #383B45;
          border-radius: 6px;
          color: #CCCCCC;
        }
      }
      
      
    }
    .download_icon {
      display: none;
    }
    .viewer_icon {
      display: none;
    }
  
  }

  &.checked {
    
  }
  

  .go-to-icon {
    display: none;
  }


  >.header {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 16px 10px 16px;
    .checkbox {
      padding: 0px;
      input { // checkbox
        width: 40px;
        height: 40px;
        transform: translate(-25%, -25%);
      }
    }
  }
  >.cad-img {
    width: 224px;
    height: 224px;
    text-align: center;
    >img {
      width: 222px;
      height: 222px;
      background-color: #161A24;
    }
  }
  >.content {
    padding: 10px 0px 20px 0px;
    >.key-value {
      padding: 0px 16px;
      height: 20px;
      box-sizing: border-box;
      display: flex;
      flex-flow: row;
      align-items: flex-end;
     
      >.key {
        width: 100px;
        font-size: 12px;
        color: #999;
        display: flex;
        align-items: center;
      }
      >.value {
        font-size: 12px;
        color: #ccc;
        margin-left: auto;
        display: flex;
        align-items: center;
        
        .building_value {
          width: 120px;
          // height: 20px;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: none;
        }
        
      }
      
    }
  }
}