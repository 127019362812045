.MapDownloadPreview {
  background-color: rgba(0, 0, 0, 0.7);
  position :absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  
  &.hidden {
    display: none;
  }
  .modal-wrapper {
    box-sizing: border-box;
    width: 680px;
    height: 615px;
    background: #1A1E28;
    border: 1px solid #333;
    color: #CCC;
    
    >.header {
      padding: 20px 30px;
      font-size: 12px;
      box-sizing: border-box;
      
      height: 175px;
      &.long {
        height:238px;
        padding-bottom: 0;
      }
      >.top-bar {
        display: flex;
        margin: auto;
        justify-content: space-between;
        width: 620px;
        height: 20px; 
        >.title {
          color: #999;
        font-size: 12px;
        }
        >.icon-button {
          width: 20px;
          .close-icon {
            color: #555;
            font-size: 20px;
          }
        }
      }
      >.msg {
        margin-top: 20px;
       // font-size: 14px;
      }
      >.title {
        margin-top: 10px;
        margin-bottom: 5px;
        color: #999;
        font-size: 12px;
      }
      >.file-name-wrapper {
        display: flex;
        justify-content: space-between;
        >.file-name {
          box-sizing: border-box;
          outline: none;
          width: 401px;
          height: 30px;
          background-color: #0c111B;
          //line-height: 30px;
          color: #EEE;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          border:none;
        }
        >.button-wrapper {
          display: flex;
          .cancle-btn {
            width: 100px;
            height: 30px;  
          }
          >.save-btn {
            width: 100px;
            height: 30px;
            margin-left: 5px;
          }
        }
      }
    }
    >.scene-wrapper {
      background: #111A22;
      width: 677px;
      height: 439px;
      display: flex;
      justify-content: center;
      align-content: center;
      &.background-none {
        background-color: transparent;
      }
      .scene {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;
        .Canvas {
          width: 604px;
          height: 342px;
        }
        .Canvas {
          border: 2px solid rgb(96, 96, 97);
          background-color: rgb(33, 40, 48);
        }
      }      
     
    }
  }
  .bg-modal-color {
    background-color: #1A1E28;
    z-index: 10000;
    height: 439px;
    width: 677px !important;
    position: absolute;
    width: 200px;
  }
  .loadingPage.modal-size {  
    height:490px;
    z-index: 100000;
  }
}