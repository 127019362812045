.Login {
  width: 100%;
  height: 100%;
  background: url("/img/login_background.jpg");

  > .dim {
    height: calc(100vh - 60px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 60px;
    z-index: 999999;
    > .circular-progress {
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 60px);
      color: #eee;
    }
  }

  .login-wrapper {
    background-color: rgba(35, 39, 50, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > a {
      > .logo {
        position: absolute;
        left: 30px;
        top: 18px;
      }
    }
    > .footer {
      width: 360px;
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-flow: row;
      color: #999;
      font-size: 12px;
      align-items: center;
      a {
        color: #999;
        text-decoration: none;
        white-space: pre;
      }
    }
    > .login-form {
      width: 360px;
      margin: auto;
      > .header {
        font-size: 30px;
        color: #eee;
        line-height: 125%;
        margin-bottom: 10px;
        .subheader {
          font-size: 18px;
          color: #48ddf2;
          line-height: 125%;
          margin-top: 5px;
        }
      }
      > .download-description {
        margin-top: 10px;
        color: #48ddf2;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 400;
      }
      > .login-btn-wrap {
        padding-top: 20px;
        .login-btn {
          width: 360px;
          box-sizing: border-box;
        }
      }
      > .register-wrap {
        display: flex;
        flex-flow: row;
        justify-content: center;
        padding-top: 10px;
        .register-text {
          color: #eeeeee;
          font-size: 14px;
        }
        .register-btn {
          color: #48ddf2;
          font-size: 14px;
          margin-left: 12px;
          text-decoration: underline;
        }
      }
      > .options {
        display: flex;
        flex-flow: row;
        justify-content: center;
        color: #eee;
        padding-top: 20px;
        > .find-btn {
          display: flex;
          .icon {
            margin-left: 5px;
            display: flex;
            margin-top: 2px;
          }
          .help-icon {
            display: flex;
            color: #ccc;
            width: 12px;
            height: 12px;
            margin: auto 0;
          }
        }
        > .forgot-btn {
          display: flex;
          justify-content: flex-start;
          margin-left: 10px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      > .input-wrap {
        > .login-input {
          color: #cccccc;
          width: 360px;
          margin-top: 10px;
        }
        > .input-form {
          margin-top: 10px;
          height: 40px;
          width: 360px;
          background-color: #f5f5f5;
          border-radius: 0px;
          input {
            height: 40px;
            box-sizing: border-box;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
