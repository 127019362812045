.MapDownloadModal {
  &.hidden {
    display: none;
  }
  
  position :absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  
  .help-icon{
    width: 16px !important;
  }

  >.modal-content {

    color: #ccc;
    width: 1280px;
    height: 816px;
 //   background: #232732;
    >.nav-bar {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      >.left-wrap {
        margin-left: 20px;
        >.title {
          color: #CCC;
          font-size: 18px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          .help-icon {
            margin-left: 7px;
          }
        }
      }
      >.right-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        >.save-btn {
          width: 185px;
          font-family: Noto Sans KR;
          &.disabled {
            pointer-events: none;            
          }
        }
        .exit-btn {
          margin-left: 10px;
          margin-right: 20px;
        }
      }
    }
  }


  section {
    overflow: hidden;
    position: relative;
    .snack-bar {
      position: absolute;
      top: 10px;
      .msg {
        display: "flex"; 
        align-items: "center"; 
        color: "#eee";
      }
  
      >div {
        min-width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
        background: rgba(0, 0, 0, 0.8);
      }

      &.error {
        >div {
          background: rgb(14,14,14) ;
        }
      }
    }
  
    .search-address {
      position: absolute;
      z-index: 10;
      left: 20px;
      top: 10px;
      
      >.SearchPlaceInput {
        .end-adornment {
          width: 44px;
          margin-right: 8px;
          >div {
            width: 44px;
          }
        }
        .search-wrapper {          
          .item {
            .name {
              font-size: 14px;
            }
            .keyword {
              color: #999;
            }
          }
          .icon-btn {
            height: 40px;
          }
        }
      }
    }

    .rightButtons {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 40px;
      position: absolute;
      z-index: 2;
      right: 10px;
      top: 9px;
    }

    .switchTabs {
      width: 40px;
      height: 78px;
      display: flex;
      flex-flow: column;
      background-color: #383b45;
      border-radius: 5px;
      margin: 0 0px 10px 0px;

      .switchButton {
        width: 36px;
        height: 36px;
        font-size: 10px;
        padding: 0px;
        margin: 2px;
        min-width: auto;
        color: #cccccc;
        font-weight: 500;
        &.enable {
          background-color: #ccc;
          color: #232732;
        }
      }

      .switchButton:hover {
        background-color: #ccc;
        color: #232732;
      }

      &.hidden {
        display: none;
      }
    }

    .drawing-options {
      position: absolute;
      right: 0;
      top: 98px;
      z-index: 11;
      .btn.bg-navy.btn-primary{
        min-width: 40px;
        height: 40px;
        background-color: #383B45;
        border-radius: 6px;
        padding: 0;
        right: 10px;
        &:hover {
          background-color: #273945;
        }
        .cal-area-icon {
          fill: #CCC;
          &.active {
            fill: rgb(80, 222, 241); //50def1
          }
        }
      }
    }
  }

  .marker-wrapper {
    position: relative;
    width: max-content;
    >.marker {
      width: fit-content;
      height: 43px;
      border-radius: 23px;
      padding: 5px;
      box-sizing: border-box;
      background-color: #0475f4;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: normal;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >.icon-wrapper {
        width: 33px;
        height: 33px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        >.place-icon {
          filter: invert(45%) sepia(93%) saturate(5600%) hue-rotate(201deg) brightness(100%) contrast(97%);
        }
      }
      >.text {
        font-size: 14px;
        height: 33px;
        padding-left: 5px;
        padding-right: 7px;
        line-height: 33px;
        font-weight: 900;
        color: white;
        z-index: 10;
      }
    }

    >.tail {
      width: 0;
      height: 0;
      position: absolute;
      left: 17px;
      bottom: -5px;
      border-color: #0475f4 transparent transparent;
      border-style: solid;
      border-width: 6px 4px 0;
      pointer-events: none;
      &:after {
        opacity: .1;
        position: absolute;
        bottom: -2px;
        left: -5px;
        width: 10px;
        height: 3px;
        background-color: #000;
        filter: blur(1px);
        content: "";
      }
    }
  }

  .area-wrap {
    border: 1px solid black;
    width: max-content;
    height: 30px;
    background-color: #000;
    display: flex;
    padding: 8px 10px;
    box-sizing: border-box;;
    justify-content: center;
    align-items: center;
  
  }
  .key {
    color: #CCC;
    margin-right: 10px;
  }
  .value {
    color: #eee;
  }
  
}