.ProjectListTable {
  display: flex;
  flex-flow: column;
  width: 1200px;
  >.table-header {
    width: inherit;
    
    .tr {
      display: flex;
      flex-flow: row;
      background-color: #161A24;
      height: 51px;
      border-bottom: 1px solid #555555;
      >.td {
        height: inherit;
        background-color: inherit;
      }
      >.th {
        height: inherit;
        background-color: inherit;
        display: flex;
        flex-flow: row;
        >.icon {
          width: 18px !important;
          height: 18px !important;
          margin-left: 5px;
        }
      }
      >.checkbox {
        width: 40px;
        height: 100%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        >.checkbox {
          width: 40px;
          &.checked {
            color: #95e4b3;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        input {
          width: 40px;
          height: 45px;
        }
      }
      >.project-field  {
        display: flex;
        flex-flow: row;
        align-items: center;
      }
      >.user-project-id {
    
        width: 70px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        >.name {
          display: flex;
          height: 50%;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          padding-top: 10px;
        }
        >.arrow {
          padding-top: 2px;
          align-items: flex-start;
          height: 50%;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          .icon {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
      >.project-type {
        width: 80px;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
        
      }
      >.status {
        width: 50px;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
      }
      >.building-type {
        width: 90px;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
  
      }
      >.project-name {
        width: 520px; 
        padding-top: 10px;
      }
      >.favorite {
        width: 40px;
      }
      >.area {
        width: 120px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        >.name {
          display: flex;
          height: 50%;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          padding-top: 10px;
        }
        >.arrow {
          padding-top: 2px;
          align-items: flex-start;;
          height: 50%;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          .icon {
          
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
      >.floor-area-ratio {
        width: 90px;
        padding-top: 10px;
      }
      >.created-at {
        width: 100px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        >.name {
          display: flex;
          height: 50%;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          padding-top: 10px;
        }
        >.arrow {
          padding-top: 2px;
          align-items: flex-start;
          height: 50%;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          .icon {
            width: 10px !important;
            height: 10px !important;
            
          }
        }
      }
    }
    .th {
      display: inline-flex;
      color: #ccc;
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }

  .noItemText {
    display: flex;
    width: 1200px;
    height: 500px;
    position: relative;
    background-color: #232732;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #999999;

    &.card {
      margin-bottom: 30px;
    }
  }
  
}

.ProjectTableRow {
  display: flex;
  flex-flow: row;
  background-color: #232732;
  height: 50px;
  border-bottom: 1px solid #333333;
  align-items: center;
  cursor: pointer;
  &.mouseOver {
    background-color: #273945;
  }

  .link {
    width: fit-content;
    display: flex;
  }

  .td {
    height: 50px;
    display: inline-flex;
    color: #ccc;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .th {
    display: inline-flex;
    color: #ccc;
    font-size: 14px;
    text-align: left;
    //padding-left: 10px;
    //padding-right: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #555555;
  }

  >.checkbox {
    width: 40px;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    >.checkbox {
      width: 40px;
      &.checked {
        color: #95e4b3;
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
    input {
      width: 40px;
      height: 45px;
    }
  }
  .project-field  {
    display: flex;
    flex-flow: row;
    align-items: center;
    &.user-project-id {
      width: 70px;
      justify-content: center;
    }
    &.project-type {
      width: 80px;
      padding-left: 10px;
    }
    &.status {
      width: 50px;
      padding-left: 10px;
      justify-content: center;
    }
    &.building-type {
      width: 90px;
      padding-left: 10px;
      cursor: pointer;
      justify-content: center;

    }
    &.project-name {
      width: 530px;
      >.value {
        width: 510px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
      }
      >.memo {
        width: 20px;      
        line-height: 20px;  
      }
    }
    
    &.favorite {
      width: 30px;
      >.favorite-btn {
        min-width: 0px;
        width: 40px;
        height: 40px;
        color: #999;
        >.active {
          color: #95e4b3;
        }
      }
    }
    &.area {
      width: 120px;
      flex-flow: row-reverse;
    }
    &.floor-area-ratio {
      width: 90px;
      flex-flow: row-reverse;
    }
    &.created-at {
      width: 100px;
      padding-left: 20px;
      padding-right: 0px;
    }
  }
  
}

.ProjectTableDetailRow {
  display: flex;
  flex-flow: row;
  background-color: #1A1E2B;
  height: 40px;
  border-bottom: 1px solid #333333;
  height: fit-content;
  cursor: pointer;
  &.mouseOver {
    background-color: #23313C;
  }
  >.detailInfo {
    margin-left: 330px;
    >.detailRow {
      display: flex;
      height: 24px;
      >.rowTitle {
        margin-left: 15px;
        width: 80px;
        align-items: center;
        color: #999999;
        font-size: 12px;
        font-family: 'Noto Sans KR';
      }
      >.rowValue {
        margin-left: 10px;
        align-items: center;
        color: #cccccc;
        font-size: 12px;
        font-family: 'Noto Sans KR';
      }
    }
  }
}

.ProjectListCardHeader {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  background-color: #161A24;
  >.leftSide {
    display: flex;
    flex-flow: row;
    align-items: center;
    >.checkbox {
      width: 40px;
      height: 40px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      >.checkbox {
        width: 40px;
        &.checked {
          color: #95e4b3;
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
      input {
        width: 40px;
        height: 45px;
      }
    }

    >.th {
      height: inherit;
      background-color: inherit;
      display: flex;
      flex-flow: row;
      align-items: center;
      color: #cccccc;
      >.icon {
        width: 18px !important;
        height: 18px !important;
        margin-left: 5px;
      }
    }

    >.user-project-id {
      width: 70px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      >.name {
        display: flex;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
      }
      >.arrow {
        padding-top: 2px;
        align-items: flex-start;
        height: 50%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       

        .icon {
          width: 10px !important;
          height: 10px !important;
        }
      }
    }
    >.project-type {
      width: 80px;
      justify-content: center;
      padding: 0px;
      cursor: pointer;
    }
    >.status {
      width: 50px;
      justify-content: center;
      padding: 0px;
      cursor: pointer;
    }
    >.building-type {
      width: 90px;
      justify-content: center;
      padding: 0px;
      cursor: pointer;
    }
  }

  >.rightSide {
    display: flex;
    flex-flow: row;
    align-items: center;

    

    >.floor-area-ratio {
      width: 120px;
    }
  }
}

.cardTable {
  position: relative;
  top: 30px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;

  .projectCard {
    position: relative;
    width: 285px;
    //height: fit-content;
    height: 470px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #232732;
    display: flex;
    flex-flow: column;
    // background-color: red;
    margin-bottom: 30px;
    cursor: pointer;

    &.mouseOver {
      background-color: #273945;
    }

    .link {
      width: fit-content;
      display: flex;
    }
    .cardToolTip{
      position: absolute;
      top: 192px;
      width: 285px;
      height: 0px;
    }

    .header{
      display: flex;
      width: 285px;
      height: 20px;
      margin-top: 20px;
      margin-bottom: 10px;

      .cardCheck{
        display: flex;
        width: 20px;
        height: 20px;
        margin-left: 22px;
        color: #999999;

        &.checked {
          color: #95e4b3;
        }
      }
      
      .idNumber{
        display: flex;
        margin-left: 12px;
        margin-top: 2px;
        margin-bottom: 2px;
        font-weight: bold;
        line-height: 16px;
      }

      .type {
        margin-left: 10px;
        width: 60px;
        padding: 0px;
      }

      .statusField {
        margin-left: 10px;
        width: 20px;
      }
      
      >.favorite {
        display: flex;
        align-items: center;
        height: 20px;
        margin-left: auto;
        margin-right: 20px;
        width: 20px;
        cursor: pointer;
        color: #999999;
      }
      
    }

    .img {
      position: relative;
      width: 285px;
      height: 285px;
      background-color: #232732;
      border-color: #232732;
      
    }
    .img img{
      width: 279px;
      height: 279px;
      margin: 3px;

    }

    .no-img {
      display: flex;
      position: relative;
      width: 285px;
      height: 285px;
      background-color: #232732;

      .inner-background {
        display: flex;
        width: 279px;
        margin: 3px 3px 3px 3px;
        background-color: #161A24;
        flex-direction: column;

        .icon-field {
          display: flex;
          width: 279px;
          height: 30px;
          margin-top: 120px;
          align-items: center;
          justify-content: center;
          color: #cccccc;
          .status-icon {
            display: flex;
            width: 30px !important;
            height: 30px !important;
          }
        }

        .no-img-text {
          width: 279px;
          margin-top: 13px;
          color: #999999;
          font-size: 14px;
          line-height: 16px;
          font-family: Noto Sans KR;
          text-align: center;
          font-weight: bold;
        }
      }
    }

    .building-type {
      position: relative;
      margin-top: 10px;
      width: 245px;
      height: 20px;
      align-items: center;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name-wrapper {
      position: relative;
      width: 245px;
      height: 20px;
      align-items: center;
      margin-left: 20px;
      margin-top: 5px;
      display: flex;

      >.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
        max-width: 225px;
      }
      >.memo {
        width: 20px;        
      }
    }
      .tailContent {
        margin-top: 5px;
        display: flex;
        height: 20px;
          
        .area {
          display: flex;
          width: 140px;
          height: 20px;
          margin-left: 20px;
          line-height: 14px;
          align-items: center;
        }

      }

      .floorRatio {
        position: relative;
        width: 245px;
        height: 20px;
        align-items: center;
        margin-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }
    }
  }