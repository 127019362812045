.resizable {
  z-index: 1000;
}
.errorLog {
 // height: min-content;
  &:hover {
//    background-color: rgba(100, 100, 100, 0.1);
  }
  background-color: #1a1e28;
  > .errorLogHead {
    border: 1px solid #333;
    border-right: 0px;
    background-color: #161A24;
    display: flex;
    justify-content: space-between;
    height: 26px;
    box-sizing: border-box;
    >.leftSide {
      display: flex;
      align-items: center;
    }
    .headText {
      font-weight: 700;
      margin: auto;
      margin-left: 4px;
      }
    .log-expand-btn {
      padding: 0;
      .icon {
        &.speaker-icon {
          width: 15px !important;
        }
        &.expand-icon {
          width: 20px;
          height: 20px;
          color: #555;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

  }

  .errorLogBody {
    display: flex;
    height: 256px;
    &.display-none {
      display: none;
      height: 0;
    }
    .expansion {
      .arrow-right {   
      }
     
      .MuiExpansionPanelSummary-expandIcon {
        left: 8px;
      }
      .expansion-title {
        height: 30px !important;
        min-height: 30px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        font-size: 14px;
      
        div {
          margin: 0;
        }
        .arrow-btn {
          width: 40px;
          padding: 0;
          
        }
        .content {

        }
      }
      .expansion-content {
        display: block;
        //height: 24px ;
        align-items: center;
        padding: 2px;
        border-bottom: none;
      }
    }

    > .category {
      box-sizing: border-box;
      background-color: #1E222E;
      width: 160px;
      height: 282px;
      border: 1px solid #333;
      border-top: 0px;
   
      >.logName {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        
        .closeIcon {
          width: 16px !important;
          color: #555;
          cursor: pointer;
        }
      }
      > .cell {
        box-sizing: border-box;
        height: 30px;
        display: flex;
        color: #ccc;
        border-bottom: 1px solid #333;
        cursor: pointer;
        &.completion {
          cursor: default;
        }
        > .icon {
          width: 16px !important;
          
          height: 16px;
          margin: auto 2px auto 10px;
          &.complete-icon {
            width: 14px !important;

            fill: #48DDF2;
          }
          &.red {
            color: #e81c1c;
          }
          &.yellow {
            width: 15px !important;
            color: #ffb068;
          }
          &.green {
            color: #95E4B3 !important;
          }
        }
        > .text {
          font-size: 14px;
          margin: auto 0;
          margin-left: 4px;
        }

        &.active {
          background: #60626a;
        }

        &.hidden {
          display: none;
        }
      }
      > .cell:hover {
        &:not(.completion) {
          background: #60626a;

        }
      }
    }

    >.errorList {
      width: calc(100% - 160px);
      //width: 100%;
      height: 200px;
      overflow: auto;
      .completeLog {
        height: 30px;
        color: #eee;
        font-size: 14px;
        display: flex;
        align-items: center;
        .icon {
          &.complete-icon {
            fill: #48DDF2;
            width: 15px !important;
            margin-left: 13px;
            margin-right: 13px;
          }
        }
      }
      div > div {
        background: none;
      }
      .log-header {
        height: 30px;
        display: flex;
        align-items: center;   
        cursor: pointer;
        .icon {
          &.arrow-right {
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
      .info {
        cursor: pointer;
        //padding: 5px 0;
        min-height: 24px;
        height: max-content;

        display: flex;
        align-items: center;
        // border-right: 1px solid #333;

        > .icon {
          width: 15px !important;
          height: 15px !important;
          margin: 3px 10px auto 32px;

          &.red {
            color: #e81c1c;
          }
          &.yellow {
            color: #ffb068;
          }
          &.green {
            color: #95E4B3;
          }
        }
        .text {
          font-size: 14px;
          margin: 1px;
          color: #ccc;

          &.sub {
            font-size: 12px;
          }
        }
        &:hover {
          &.error {
            background: rgba(232, 28, 28, 0.2);
          }
          &.warning {
            background-color: rgba(255, 176, 104, 0.2);
          }
          &.info-log {
            background-color: #4b725b4b; 
          }
        }
      }
     
    }
  }
}
