.designer-download-page {
  display: flex;
  min-height: calc(100vh - 80px);
  background-color: #161a24;
  justify-content: center;
  align-items: center;

  > .bg-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 80px);
    min-height: 490px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    > .bg-img {
      width: 100%;
      opacity: 0.4;
    }
  }

  > .content-wrapper {
    z-index: 2;
    width: 1200px;
    height: 485px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    > .title {
      color: #eeeeee;
      font-size: 30px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      line-height: 35px;
    }

    > .downloder-wrapper {
      width: 1200px;
      height: 420px;
      margin-top: 30px;
      background-color: rgba(#232732, 0.8);
      border-radius: 6px;
      display: flex;
      flex-direction: column;

      > .button-wrapper {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        > .title {
          margin-left: auto;
          margin-right: auto;
          font-size: 24px;
          line-height: 18px;
          color: #eeeeee;
        }
        > .sub-title-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          margin-bottom: 20px;
          font-size: 18px;
          color: #cccccc;
          .sub-title {
            display: flex;
            justify-content: center;
          }
        }
        > .download-inner-wrapper {
          display: flex;
          width: 100%;
          margin-top: 20px;
          justify-content: center;
          align-items: center;
          .version-wrapper {
            justify-content: center;
            align-items: center;
            margin-right: -10px;
            width: 230px;
            height: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            border-radius: 6px 0 0 6px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #ccc;
            font-weight: 400;
          }
          .download-btn {
            font-size: 14px;
            width: 230px;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 0;
            &.designer-download-btn {
              .icon {
                color: #232732;
              }
            }
          }
          .launch-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 17px;
            height: 17px;
          }
        }
      }
      > .grey-line {
        margin-top: 85px;
        width: -1px;
        border: 1px solid #333333;
      }
    }
  }
}
