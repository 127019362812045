.CustomerSupport {
  background-color: #1a1e28;
  min-height: calc(100vh - 280px);
  margin-bottom: 100px;
  display: flex;
  flex-flow: column;

  .CustomerSupportHeader {
    background-color: #161a24;
    > .wrapper {
      width: 1200px;
      margin: auto;
      > .content {
        > .header {
          height: 20px;
          margin: 20px 0px;
          margin-left: auto;
          width: fit-content;
          > .breadcrumb {
            color: #999;
            width: inherit;
          }
        }
        > .body {
          display: flex;
          flex-flow: row;
          margin-bottom: 20px;
          > .page-title {
            color: #eee;
            font-size: 30px;
            width: fit-content;
            height: 40px;
            font-family: Noto Sans KR;
          }
        }
      }
    }
  }

  .CustomerSupportBody {
    background-color: #1a1e28;
    padding-top: 30px;
    display: flex;
    .wrapper {
      width: 1200px;
      margin: auto;
      display: flex;

      .support {
        display: flex;
        width: 285px;
        flex-direction: column;
        .support-header {
          display: flex;
          height: 30px;
          width: 285px;
          font-family: "Noto Sans KR";
          font-size: 18px;
          line-height: 26px;
          align-items: center;
          color: #cccccc;
        }
        .button {
          flex-direction: column;
          background-color: #232732;
          border-radius: 6px;
          display: flex;
          margin-top: 20px;
          width: 285px;
          height: 130px;
          color: #cccccc;

          &.guide-btn {
            cursor: pointer;
            &:hover,
            &.active {
              background-color: #273945;
            }
          }
          &.guide-btn-no-link {
            padding-bottom: 20px;
            background-color: #1a1e28;
            border: 2px solid #232732;
            border-radius: 6px;
          }

          > .title {
            margin-top: 20px;
            margin-left: 20px;
            display: flex;
            width: 245px;
            height: 40px;
            align-items: center;

            > .content {
              display: flex;
              height: 21px;
              font-size: 18px;
              line-height: 21px;
              font-family: Noto Sans KR;
            }

            > .icon {
              margin-right: 5px;
              margin-left: auto;
              display: flex;
              width: 20px;
              height: 20px;
            }
          }

          > .detail {
            margin-left: 20px;
            margin-top: 0px;
            display: flex;
            width: 245px;
            height: 50px;
            align-items: center;

            > .content {
              display: flex;
              flex-direction: column;
              height: 40px;
              font-size: 14px;
              line-height: 20px;
              font-family: "Noto Sans KR";
              .accent {
                margin-top: 5px;
                font-weight: 700;
                justify-content: flex-start;
              }
              .email-copy-btn {
                background-color: inherit;
                margin-right: auto;
                color: #ccc;
                border: none;
                cursor: auto;
                padding-left: 0;
              }
            }
          }
        }
      }

      .FAQ {
        display: flex;
        width: 834px;
        margin-left: 81px;
        flex-direction: column;

        .faq-header {
          display: flex;
          height: 30px;
          width: 285px;
          font-family: "Noto Sans KR";
          font-size: 18px;
          line-height: 26px;
          align-items: center;
          color: #cccccc;
        }

        .faq-table {
          display: flex;
          width: 834px;
          height: fit-content;
          margin-top: 20px;
          flex-direction: column;
          > .table-header {
            display: flex;
            width: inherit;
            height: 45px;
            background-color: #161a24;
            border-bottom: 1px solid #555555;
            color: #cccccc;
            font-size: 14px;
            font-family: "Noto Sans KR";
            > .division {
              display: flex;
              width: 120px;
              height: inherit;
              align-items: center;
              padding-left: 20px;
            }
            > .content {
              display: flex;
              width: 714px;
              height: inherit;
              align-items: center;
            }
          }
          > .table-content {
            display: flex;
            width: inherit;
            height: fit-content;
            flex-direction: column;

            > .table-row {
              display: flex;
              width: inherit;
              height: fit-content;
              flex-direction: column;
              background-color: #232732;
              color: #cccccc;
              font-size: 14px;
              font-family: "Noto Sans KR";

              &:hover,
              &.active {
                background-color: #273945;
              }

              > .row-header {
                display: flex;
                width: inherit - 20px;
                height: 50px;
                border-bottom: 1px solid #333333;
                margin-bottom: -1;

                .icon {
                  width: 25px !important;
                  height: 25px !important;
                  color: #eeeeee;
                }

                .category {
                  display: flex;
                  flex-basis: 14.38%;
                  height: inherit;
                  align-items: center;
                  margin-left: 0px !important;
                }

                .title {
                  display: flex;
                  height: inherit;
                  align-items: center;
                }
              }

              > .row-header.expanded {
                min-height: 50px;
              }

              .row-content {
                display: flex;
                width: inherit;
                height: fit-content;
                align-items: center;

                .content_column {
                  width: 613px;
                  margin-top: 10px;
                  margin-left: 112px;
                }
              }
            }

            > .table-row.expanded {
              margin: auto;
              border-bottom: 1px solid #555555;
            }
          }
        }
      }
    }
  }
}
