.Memo {
  >.memo-tooltip {     
    background-color: #161A24;
    background: #161A24;
    color: #ccc;
    font-size: 12px;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow-y: auto;  
    z-index: 1000000 !important;
  }   
}

.MemoIcon {  
  .svg {
    color: inherit;
  }    
  color: #ccc;  
  .icon {
    width: 10px !important;
    height: 10px !important;
  }
}
