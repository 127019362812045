.Home {
  background-color: #1A1E28;
  min-height: calc(100vh - 280px);
  padding-bottom: 100px;

  .top-bar {
    top: 0px;
  }

  >.home-wrapper {
    >.header {
      background-color: #161A24;
      display: flex;
      flex-flow: row;
      align-items: center;
      padding-top: 60px;
      padding-bottom: 30px;
      box-sizing: border-box;
      >.wrapper {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-flow: row;
        margin-top :20px;
        height: 40px;
        margin-top: 20px;
        justify-content: space-between;
        >.tooltip-wrap {

        }
        a{
          text-decoration: none;
        }
        >.title {
          color: #eee;
          font-size: 30px;
        }

        .right-info-wrapper{
          display: flex;
          align-items: center;
        
        .point-info {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
          margin-left: auto;
          //width: 125px;
          height: 40px;
          >.name {
            >.buildit-point-logo {
              width: 16px !important;
              height: 16px !important;
            }
            >.useable-point {
              line-height: 17px;
            }
            color: #999;
            font-size: 14px;
            margin-right: 2px;
            display: flex;
            flex-flow: row;
            align-items: center;
          }
          .point {
            color: #ccc;
            font-size: 18px;
            font-weight: 400;
          }
          
        }
        .PLATINUM-wrapper {
          width: 149px;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #48DDF2;
          font-weight: 700;
          line-height: 40px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          letter-spacing: -0.05em;
          margin-left: 20px;
        //  justify-content: space-around;
          box-sizing: border-box;
          .PLATINUM {
            background: linear-gradient(95.5deg, #48DDF2 16.29%, #95E4B3 84.25%);
            background-clip: text;
            color: transparent;
            font-size: 18px;
          }
          .PLATINUM-using {
            margin-left: 3px;
            color: #48DDF2;
            font-size: 14px;

          }
        }
      
      }
      }
    }
    >.body {
      box-sizing: border-box;
      padding-top: 40px;
      background-color: #1A1E28;
      >.main-panel {
        width: 1200px;
        margin: auto;
        border-bottom: 1px solid #333;
        padding-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row;
        >.new-project {
          display: flex;
          flex-flow: column;
          >.header {
            box-sizing: border-box;
            color: #ccc;
            font-size: 18px;
            padding-bottom: 20px;
            height: 50px;
          }
          >.body {
            
          }
        }
        >.finished-projects {
          display: flex;
          flex-flow: column;
          width: 100%;
          >.header {
            box-sizing: border-box;
            color: #ccc;
            font-size: 18px;
            padding-bottom: 20px;
            display: flex;
            flex-flow: row;
            align-items: center;
            width: 100%;
            height: 50px;
            >.tabs { 
              width: 146px;

            }
          }
          >.body {
            display: flex;
            flex-flow: row;
            align-items: center;
            a, .HomeProjectCard {
              margin-right: 20px;
              .HomeProjectCard {
                margin-right: 20px;
                &:last-child {
                  margin-right: 0px;
                }
              }
              &:last-child {
                margin-right: 0px;
              }
            }

            .HomeProjectCard {
              margin-right: 20px;
              &:last-child {
                margin-right: 0px;
              }
            }

            >.empty-result {
              box-sizing: border-box;
              border-radius: 6px;
              width: 100%;
              height: 410px;
              background-color: #232732;
              display: flex;
              color: #999;
              font-size: 14px;
              flex-flow: column;
              >.empty-img {
                margin-top: 50px;
                text-align: center;
              }
              >.content {
                display: flex;
                flex-flow: column;
                margin-left: 130px;
                margin-right: 130px;
                margin-top: auto;
                margin-bottom: auto;
                >.main-title {
                  color: #eee;
                  font-size: 24px;
                  margin-bottom: 10px;
                  font-weight: bold;
                }
                >.sub-title {
                  display: flex;
                  flex-flow: row;
                  .msg {
                    color: #ccc;
                    font-size: 14px;
                  }
                  >.new-project-btn {
                    margin-left: auto;
                    font-size: 18px;
                    width: 200px;
                  }
                }
              }
            }
          }
        }
      }
      >.sub-panel {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-flow: row;
        align-items: center;
        padding-top: 30px;
      }
    }
  }
}

.ProjectListSummary {
  width: 590px;
  box-sizing: border-box;
  >.header {
    color: #ccc;
    font-size: 18px;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-bottom: 20px;
    height: 30px;
  }
  >.body {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    >.project-summary-table {
      >.empty {
        display: flex;
        align-items: center;
        color: #999;
        height: 200px;
        box-sizing: border-box;
        flex-flow: row;;
        justify-content: center;
        >.msg {
          display: flex;
          flex-flow: column;
          align-items: center;
        }
        &.tr {
          height: 40px;
          border-bottom: 1px solid #333;
          &:last-child {
            border-bottom: 0px solid #333;
          }
        }
      }
      display: flex;
      flex-flow: column;
    
      a {
        &:last-child {
          >.tr {
            border-bottom: none;
          }
        }
        >.tr {
          display: flex;
          flex-flow: row;
          align-items: center;
          height: 40px;
          box-sizing: border-box;
          border-bottom: 1px solid #333;
          background-color: #232732;
          &:hover {
            background-color: #273945;
            cursor: pointer;
          }
          >.td {
            padding-left: 10px;
            padding-right: 10px;
            color: #ccc;
            font-size: 14px;
            box-sizing: border-box;
            display: flex;
            flex-flow: row;
            align-items: center;
  
            &.user_project_id {
              width: 60px;
  
            }
            &.project_type {
              width: 80px;
            }
            &.project_name {
              width: 450px;
              .name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

.HomeProjectCard {
  width: 285px;
  height: 470px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #232732;
  display: flex;
  flex-flow: column;
  &.empty, &.first-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    flex-flow: column;
    border: 1px solid #333;
//    background-color: inherit;
    border: none;
    background-color: #232732;
    &:hover {
//      background-color: inherit;
      background-color: #232732;
      cursor: default;
    }

    >.content {
      >.title {
        color: #999;
        text-align: center;
      }
      .btn{
        margin-top: 20px;
      }
      >.btn {
        width: 200px;

      }
    }
  }
  &.empty {
    background-color: #232732;
    border: none;
    &:hover{
      background-color: #232732;
    }
    >.msg {

      color: #999999;
    }
  }

  &:hover {
    background-color: #273945;
    cursor: pointer;
  }

  >.header {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 20px 20px 10px 20px;
    >.user-project-id {
      color: #ccc;
      font-size: 14px;
    }
    >.project-type-tag {
      margin-left: 10px;
    }
    >.statusField {
      margin-left: 10px;
      width: 20px;
    }
    >.favorite {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 20px;
      margin-left: auto;
      width: 20px;
      cursor: pointer;
      color: #999999;
    }
  }
  >.view-panel {
    >.img {
      width: 285px;
        height: 285px;
      >img {
        margin:3px;
        width: 279px;
        height: 279px;
      }
    }
  }

  >.body {
    >.project-name {
      color: #eee;
      font-size: 14px;
      height: 20px;
      padding: 0px 20px;
      margin: 10px 0px 0px 0px;
      width: 245px;
      display: flex;
      align-items: center;
      >.name {
        width: 225px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        
      }
      >.memo {
        width: 20px;        
      }
    }
    >.project-value {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 20px;
      padding-left: 20px;
      padding-right: 20px;
      color: #ccc;
      font-size: 12px;
      >.floor-area-ratio {
        margin-left: 5px;
      }
    }
  }
  .building-type {
    position: relative;
    margin-top: 10px;
    width: 245px;
    height: 20px;
    align-items: center;
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tailContent {
    margin-top: 5px;
    display: flex;
    height: 20px;
      
    .area {
      display: flex;
      width: 140px;
      height: 20px;
      margin-left: 20px;
      line-height: 14px;
      align-items: center;
    }

   
  }
  .floorRatio {
    position: relative;
    width: 245px;
    height: 20px;
    align-items: center;
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
}

.icon_growns { 
  
  animation-duration: 1s;
  animation-name: growns;
  @keyframes growns{
    0% {
      transform: scale(0.1);      
    }
    100% {
      transform: scale(1);            
    }
  }
}



// .Home {
//   .show-project-list-fixed {
//     position: absolute;
//     top: 125px;
//     right: 125px;
//     padding: 20px;
//     overflow-y: auto;
//     .title {
//     }
//     .content {
      
//       .item {
//         width: 100%;
//         margin: 5px;
//         height: 200px;
//         border: 2px solid #ddd;
//         display: flex;
//         flex-flow: row;
        
//         .map {
//           width: 150px; 
//           height: 150px;
//         }

//         .project-info {
//           display: flex;
//           flex-flow: column;
//         }
//       }
//     }
//   }

//   .home-btns {
//     position: absolute;
//     bottom: 50px;
//     display: flex;
//     flex-flow: row;
//     justify-content: center;
//     width: 100%;
//     >.btn {
//       svg {
//         width: 20px;
//         height: 20px;
//       }
//     }
//     .create-btn {
//       width: 180px;
//       font-size: 18px;
//     }
//     >a {
//       text-decoration: none;
//     }
//   }  
// }