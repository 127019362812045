
.MySiteBlockSaveModal {
  background-color: rgba(0, 0, 0, 0.7);
  position :absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }
  .modal-wrapper {
    box-sizing: border-box;
    width: 700px;
    height: 733px;
    background: #1A1E28;
    border: 1px solid #333;
    color: #CCC;
    &.myType {
      height: 626px;
    }
    >.header {
      padding: 20px 30px 20px 30px;
      font-size: 12px;
      box-sizing: border-box;
          
      height:250px; // 동평면
      &.long {
        height: 293px;
      }
      &.myType {
       // height: 397px;
      }

      >.top-bar {
        display: flex;
        margin: auto;
        justify-content: space-between;
        width: 640px;
        height: 20px; 
        >.title {
          color: #999;
        font-size: 12px;
        }
        >.icon-button {
          width: 20px;
          .close-icon {
            color: #555;
            font-size: 20px;
            &.hidden {
              display: none;
            }
          }
        }
      }
      >.msg {
        margin-top: 20px;
        height: 30px;
       // font-size: 14px;
      }
      >.title {
        margin-top: 10px;
        margin-bottom: 5px;
        color: #999;
        font-size: 12px;
      }
      >.file-name-wrapper {
        display: flex;
        justify-content: space-between;
        >.file-name {
          box-sizing: border-box;
          outline: none;
          width: 425px;
          height: 30px;
          background-color: #0c111B;
          //line-height: 30px;
          color: #EEE;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          border:none;
        }
        >.button-wrapper {
          display: flex;
          .cancle-btn {
            width: 100px;
            height: 30px;  
          }
          >.save-btn {
            width: 100px;
            height: 30px;
            margin-left: 5px;
          }
        }
      }
      .font-error {
        display:flex;
        align-items: center;
      }
    }
    >.scene-wrapper, >.map-wrapper {
      background: #111A22;
      width: 698px;
      height: 438px;
      display: flex;
      justify-content: center;
      align-content: center;
      &.myType {
        height: 320px;
       // padding-top: 49px;
      }
      &.background-none {
        background-color: transparent;
      }
      .scene {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;

        .Canvas, .map {
          width: 640px;
          height: 380px;
        }
        .Canvas {
         // border: 2px solid rgb(96, 96, 97);
          background-color: rgb(33, 40, 48);
        }
      }
    }
  }
  .saveNameMsg {
    height: 20px;

  }
  .border {
    width: 640px;
    height: 1px;
    background-color: #333;
  }
  .title-wrapper {
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 640px;
    display: flex;
    .title {
    
    }
    .more {
      display: flex;
      align-items: center;

    }
  }


  .input-summary {

    display: flex;
    width: 640px;
    height: 30px;
    display: flex;
    align-items: center;
    li span {
      position: relative;
      left: -7px;
    }
    >.edited {
      color: #95E4B3;
    }
  }
  .key-value-wrapper {
    display: flex;
    >.key-value {
      width: 120px;
      margin-right: 10px;
      &.width-250px {
        width: 250px;
      }
      >.key {
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        color: #999999;
        display: flex;
        align-items: center;

        .info-icon {
          width: 13px !important;
          color: #eee;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 4px;
          cursor: pointer;
          &.active {
            color: #95E4B3;
          }
        }
      }
      >.wrap-value {
        display: flex;
        .btn {
          width: 30px;
        }
      }
      >.value {
        height: 24px;
        color: #CCC;
        font-size: 14px;
        display: flex;
        line-height: 40px;
        align-self: center;
        align-items: center;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.font-green {
          color: #95E4B3;
        }
      }    
    }
  }
  .loading-wrap {
    z-index: 2001;
    height: 300px;
    .modal-size {
      width: 698px;
    
    }  
    
  }
}

.display-none {
  visibility: hidden;
}
