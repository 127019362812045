.router-link {
  width: fit-content;
  .CopyProjectInfo {
    color: #CCC !important;
    font-weight: 700;
    font-size: 12px;
    padding-right: 7px;
    height: 20px;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-top: 5px;
    &:hover {
      background-color: #273945;
    }
    &.project-form {
      padding-left: 10px;
      height: 40px;
      margin-top: 0px;
      padding-right: 10px;
    }
    >.text {
      letter-spacing: -0.05em;
      margin-right: 5px;
      line-height: 1.5em;
    }
    >.icon {
      width: 16px !important;
      &.arrow-icon {
        color: #999;
        margin-right: 2px;
      }
    }
  }
}