.inputBody {
  width: 135px;
  margin-right: 10px;

  &:first-child {

  }
  // >.name {
  //   color: #999;
  // }
  .label-wrap{
    display: flex;
    position:relative;
    justify-content: space-between;
    .label {
      font-size: 12px;
      color: #999999;
      margin-bottom: 5px;
      height: 20px;
      width: 185px;
      display: flex;
      align-items: center;
      width: 135px;
      height: 20px;
      justify-content: space-between;
      .refresh-icon {
        color: #555;
        width: 14px !important;
        &.reset-icon-on {
          color: #eee;
        }
      }
  
    }
  }

  > .box {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 30px;
    background-color: #0c111b; 
    padding: 0 0px 0 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #eeeeee;

    &.hide {
      display: none;
    }

    &.disable {
      display: none;
    }
  }

  .label-bottom {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #95E4B3;
    height: 20px;
    margin-top: -10px;
    .icon-info {
      width: 12px !important;
      height: 12px !important;
      margin-right: 3px;
    }
  }

  .box {
    width: 135px;
    height: 30px;
    padding-left: 10px;

  }
  .value {
    margin-left: auto;
    font-size: 16px;
    padding: 0px;
    border: 0px;
    outline: none;
    overflow: auto;
    text-align: left;//right;
    background: #0c111b;
    color: #cccccc;

    span {
      font-size: 14px;
      color: #cccccc;
    }

    &.disable {
      background-color: #161A24;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .box:hover,
  .box:focus {
    border-bottom: solid 1px #999999;
  }

  .box-disable {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 40px;
    background-color: #161A24;
    padding: 0 10px 0 5px;
    box-sizing: border-box;
  }
}
