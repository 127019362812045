.SettingModalBody[role="dialog"] {
  z-index: 300;  
  margin: 20px;
}  

.SettingModalBody {
  div {
    > div {
      max-width: 608px;
      width: 608px;
      height: 640px;
      background-color: #1a1e28;
      position: relative;
      display: flex;
      // border: 1px solid #333333;
      // box-sizing: border-box;
    }
  }

  .title {
    position: relative;
    margin: 20px auto 0 auto;
    width: 548px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    color: #999;
    // > span {
    //   margin-right: auto;
    // }
    .setting {
      height: 20px;
    }
    .right-btns {
      // display: flex;
      // justify-content: flex-end;
      width: fit-content;
      height: 20px;
      .save-btn {
        width: 50px;
        height: 20px;
        margin-right: 8px;
      }
      .closeIcon {
        cursor: pointer;
        margin-left: auto;
        color: #555;
        width: 20px;
        height: 20px;
      }  
    }

  }
  .contentTitle {
    width: 548px;
    height: 40px;
    color: #eee;
    font-family: Roboto;
    display: flex;
    margin: 20px auto 0 auto;
    > span {
      font-size: 16px;
      margin: auto 0 auto 0;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin: auto 0 auto 4px;
      cursor: pointer;
      &.info {
        color: #eee;
        width: 16px !important;
      }
    }
  }

  .item {
    width: 525px;
    height: fit-content;
    display: flex;
    margin: 10px auto 0 auto;
    padding-bottom: 9px;
    border-bottom: 1px solid #333;
    flex-direction: column;

    .mainContent {
      width: 525px;
      height: 40px;

      .name {
        display:flex;
        line-height: 1.5em;
        justify-self: center;
        font-size: 14px;
        margin: auto 0;
        color: #ccc;
      }

      .switch {
        position: relative;
        display: inline-block;
        margin: auto 10px;
        width: 36px;
        height: 20px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #333;
        transition: 0.4s;
        border-radius: 30px;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;//#48ddf2;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .slider:before {
        transform: translateX(17px);
      }
      input:checked + .slider {
        background-color: #48DDF2;

//        background-color:  #01baef;
      }

      > .text {
        font-size: 14px;
        margin: auto;
        color: #999;
        &.active {
          color: #48DDF2;
        }
      }
      .icon {
        width: 18px;
        height: 18px;
     
        margin: auto 0 auto 5px;
        cursor: pointer;
        &.info {
          color: #eee;
          width: 16px !important;
        }
      }

      > .inputBox {
        width: 60px;
        height: 20px;
        background: #0c111b;
        padding: 10px;
        border: 0px;
        outline: 0px;
        color: #ccc;
        font-size: 16px;
        text-align: right;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .description {
      height: 20px;
      width: 525px;
      > span {
        color: #48ddf2;
        margin: auto 0 auto auto;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .unitDropdown {
    width: 120px;
    height: fit-content;
    font-size: 16px;
    color: #ccc;
    display: flex;
    flex-direction: column;

    .default {
      right: 0;
      width: 100px;
      height: 20px;
      padding: 10px;
      display: flex;
      background: #0c111b;
      .text {
        margin: auto auto auto 0;
      }
      .icon {
        width: 18px;
        height: 18px;
      }
    }

    .menu {
      width: 120px;
      display: none;
      height: fit-content;
      background: #0c111b;

      .item {
        height: 20px;
        width: 100px;
        padding: 10px;
        margin: 0px;
        cursor: pointer;
        background: #0c111b;
        z-index: 10;
      }
      &.show {
        display: block;
      }
    }
  }

  .switchTabs {
    width: 548px;
    height: 40px;
    display: flex;
    border-radius: 6px;
    margin: 10px auto 10px auto;
    background-color:#383B45;
    .switchButton {
      width: 180px;
      height: 36px;
      font-size: 12px;
      padding: 0px;
      margin: 2px 0 2px 2px;
      min-width: auto;
      color: #cccccc;
      border-radius: 6px;
      display: flex;
      color: #232732;
      cursor: pointer;

      .text {
        margin: auto;
      }

      &.enable {
        background-color: #eeeeee;
        color: #232732;
      }

      &.disable {
        color: #ccc;
        background-color:#383B45;
      }
    }

    &.hidden {
      display: none;
    }
  }

}
