.myType {
  >.header {
    background-color: #232732;
    height: 30px;
    margin-bottom: 10px;
    >.item {
      width: 55px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #48DDF2;
      font-size: 14px;
      background-color: #1A1E28;
    }
  }
  >.list-wrap {
    margin-left: 20px;
  }
  > .layerList {
    height: calc(100% - 127px);
    overflow: auto;

    .listButton {
      width: 190px;
      height: 30px;
      margin: 2px auto;
      background-color: #232732;
      border-radius: 6px;
      display: flex;
      cursor: pointer;

      > .text {
        font-size: 12px;
        color: #eeeeee;
        margin: auto auto auto 3px;
      }

      > .CheckIcon {
        width: 20px;
        height: 20px;
        color: #555;
        margin: auto 0px auto 10px;

        &.close {
          display: none;
        }

        &.selected {
          color: #95e4b3;
        }
      }

      .error {
        color: #e81c1c;
      }

      &.selected {
        background-color: #60626a;
      }

      &.selected:hover {
        background-color: #60626a;
        .close {
          display: inline;
        }
        .check {
          display: none;
        }
      }
    }

    .error {
      color: #e81c1c;
    }

    .listButton:hover {
      background-color: #60626a;
    }
  }

  > .arrowDiv {
    display: flex;
    align-items: flex-end;
    flex: 0 0 auto;
    width: 190px;
    height: 20px;
    margin: 20px auto 15px auto;

    > .text {
      position: relative;
      margin-right: auto;
      font-size: 12px;
      color: #999;
    }

    > .closeIcon {
      position: relative;
      color: #555;
      margin-left: auto;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  > .switchTabs {
    width: 190px;
    height: 30px;
    display: flex;
    background-color: #383b45;
    border-radius: 5px;
    margin: 0px auto 20px auto;
    font-family: Noto Sans KR;

    .switchButton {
      width: 92px;
      height: 26px;
      padding: 0px;
      margin: 2px;
      border-radius: 6px;
      display: flex;
      color: #cccccc;
      cursor: pointer;

      .text {
        font-size: 12px;
        margin: auto;
      }

      &.enable {
        background-color: #eeeeee;
        color: #232732;
      }
    }
    .switchButton:hover {
      background-color: #eeeeee;
      color: #232732;
    }

    &.hidden {
      display: none;
    }
  }

  > .description {
    font-size: 12px;
    color: #48ddf2;
    margin: 0 22.5px 10px 22.5px;

    &.hidden {
      display: none;
    }
  }
}
// ::-webkit-scrollbar { //! 스크롤바
//   display: none; 
// }
// 블록버전
.MainBody {

  .wrapBlockList {
    color: #CCC;
    border: 1px solid #333;
    margin: 0;
    box-sizing: border-box;
    width: 320px;
    background-color: #1A1E28;
    &.z-index-back {

      z-index: -1;
    }
    .header {
      z-index: 100;
      width: 320px;
      display: flex;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      border-right: none;
      background-color: #383B45;
      border: 1px solid #161A24;
      .hierarchy-icon {
        margin: auto 5px auto 13px;
      }
      .text {
        font-weight: 400;
      }
    
    }
    .wrap-tab {
      .tabs {
        background-color: #333;
        width: 320px;
        z-index: 300;
        height: 30px;
        .tab {
          font-size: 14px;
          height: 30px;
        }
      }
    }
    .description {
      padding-top: 10px;
      overflow-y: scroll ;
      width: 320px;
      height: 100%;
      box-sizing: border-box;
      .text {
        stroke: #EEE;
      }
    }

    }

  .functionalLayout {
    background-color: #1A1E28;
    width: 320px;
    box-sizing: border-box;;
    
    >.header {
      border: 1px solid #161A24;
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: center;
      color: #48DDF2;
      background-color: #383B45;
      >.wrapper {
        display: flex;
        justify-content: center;
        align-items: center; 
        .icon {
          margin-right: 5px;
          margin-left: 12px;
          width: 16px !important;
        }
        .font {
          font-weight: 400;
        }
      }
      // >.item {
      //   font-size: 14px;
      //   display: flex;
      //   background-color:  #1A1E28;
      //   width: 79px;
      //   height: 30px;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
    
    .description {
      overflow-x: hidden;
       overflow-y: scroll;

      border-left: 1px solid #333;
      border-right: 1px solid #333;
      height: 100%;
      width: 320px;
      box-sizing: border-box;
      .selectedBlockName {
        // color: #EEE;
        // font-size: 14px;
        height: 30px;
        width: 380px;
//        margin: 10px 20px;
        .title {
          .block-icon {
            stroke: #EEE;
            margin-left: 2px;
            margin-right: 7px;
          }
          display: flex;
          width: 280px;
          height: 30px;
          margin: 10px 20px;
  
          flex-direction: row;
          border-bottom: 1px solid #555;
          align-items: center;
          justify-content: space-between;
        }
      }
  
    }


//  background-color: #fff;
    >.selected-not-block {
      color:  #48DDF2;
      font-size: 12px;
      width: 380px;
      height: 20px;
      margin: 20px;

    }
  }
}
