.buildit-template {
  display: 'flex';
  width: 100%;
  // overflow-x: auto;
  // min-width: 1440px;
  .template-logo {
    flex: 1;
    font-size: 24px;
  }
  .app-bar {
    display: flex;
    justify-content: center;
    height: 60px;
    box-shadow: none;
    background-color: #232732;
    .tool-bar {
      min-height: 60px;
      height: 60px;
      padding-left: 30px;
      padding-right: 0px;
      min-width: 1200px;
      >a {
        display: flex;
      }
      .menus {
        font-family: 'Noto Sans KR';
        display: flex;
        margin-left: auto;
        align-items: center;

        .new-project-btn {
          width: 140px;
          font-size: 14px;
          padding: 0px;
        }

        .items {
          margin-right: 30px;
          display: flex;
          text-transform: none;
        }

        .dropdown {
          // float: left;
          // overflow: hidden;
          position: relative;
          a:active{ text-decoration: none; }

          &.user-email {
            width: 140px;
          }
        }
        
        .dropdown .dropbtn {
          padding: 20px 0px;
          background-color: inherit;
          margin: 0;
          .email {
            width: 105px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            text-transform: none;
          }
        }

        .dropdown-content {
          display: none;
          position: absolute;
          width: 101px;
          background: #232732;
          z-index: 1;
          left: -10px;
          &.email {
            width: 142px;
          }
        }

        .dropdown-content a {
          float: none;
          height: 40px;
          display: block;
          padding-left:10px;
          color: #CCCCCC;
          display : flex;
          flex-direction: column;
          justify-content: center;
        }

        .dropdown-content a:hover {
          background-color: #273945;
        }
        
        .dropdown:hover .dropdown-content {
          display: block;
        }

      ////

        .items:hover {
          color: #eee;
        }

        
        >.my-page-btn {
          margin-right: 22px;
          padding-left: 10px;
          width: 140px;
          position: relative;
          .email-wrap {
            max-width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: none;
            .email {
              width: 105px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-transform: none;
            }
            .icon {
              margin-left: 5px;
              color: #ccc;

            }

          }
          .select-menu {
            position: absolute;
            width: 140px;
            top: 43px;
            display: flex;
            flex-flow: column;

            >.wrap {
              >.menu {
                background-color: #232732;
                text-align: left;
                height: 40px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                color: #ccc;
                text-decoration: none;
                &:hover {
                  background-color: #273945;
                }
              } 
            }
          }
        }
      }
    }
  }

  .template-content {
    display: block;
    .app-bar-space {
      height: 60px;
    }
    height: calc(100vh);
    .main-content {
      background-color: #1A1E28;
      // min-height: 100%;
      overflow: auto;
      overflow-x: hidden;
      position: relative;
    }
    >.dim {
      height: 100vh;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
      z-index: 999999;
      pointer-events: none;
      display: flex;
      flex-flow: column;
      >.circular-progress {
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 60px);
        color: #eee;
      }
      >.content {
        position: absolute;
        top: calc(50%);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-items: center;
        color: #ccc;
        font-size: 14px;
      }
    }
  }

  .text-deco-none {
    text-decoration: none;
  }

}