.ModalBody {
  div {
    > div {
      width: 380px;
      // height: 145px;
      height: fit-content;
      // box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.3);
      background-color: #232732;
    }
  }
  .modal-title {
    width: 340px;
    height: 15px;
    padding: 20px 0 0 20px;
    font-size: 13px;
    color: #999999;
    display: flex;

    .icon {
      > svg {
        font-size: 20px;
      }
      color: #555555;
      margin-left: auto;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .modal-content {
    width: 340px;
    margin: 20px 0 0 20px;
    color: #cccccc;
    font-size: 14px;
    font-family: "Noto Sans KR";
    overflow: unset;

    > .content {
      height: fit-content;
      width: fit-content;
    }
  }

  .modal-action {
    width: 340px;
    min-height: 50px;
    margin: auto;
    margin-top: 5px;
    display: flex;
    flex-direction: row-reverse;
    button {
      width: 55px;
      height: 30px;
      border-radius: 6px;
      border: solid 1px #383b45;
      font-size: 12px;
      cursor: pointer;
      color: #999999;
    }
    .positive-btn {
      background-color: #383b45;
    }
    .negative-btn {
      margin-right: 5px;
      background-color: #232732;
    }
  }
}
