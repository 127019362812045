.ProjectTypeModal{

  h3, p {
    margin: 0;
  }
  
  >.modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.5;
    z-index: 2000;
  }
  >.modal-box {

    width: 835px;
    height: 350px;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 3000;
    background-color:  #161A24;
    text-align: center;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 24px;
      color: #EEEEEE;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
    
    }
    .header {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .MuiButton-flat-56{
        min-width: 60px;
        box-sizing: border-box;
        height: 50px;
      }
      .closeBtn {
        width: 60px;
        height: 50px;
        padding:0;

        .closeIcon{   
          color : #555555;
    //      margin-right: 24px;
          margin: 24.17px auto 14.17px auto;
          width: 20px;
          
        }
  
      }
    }

    .title-box {
      position: absolute;
      top: 50px;
      height: 40px;
      left: 50%;
      transform:translateX(-50%);
      pointer-events: none;
      >.title {

        letter-spacing: -0.05em;
        text-align: center;
        font-weight: 500;
      }
    }
    .title-box:after {
      color: blue;
    }
    section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      //margin-bottom: 50px;
      a {
        width: 50%;  
      }
      article {
        cursor: pointer;
        padding-top: 70px;
        height: 300px;
        box-sizing: border-box;
        //width: 100%;

        &.AI:hover {
          .AI.content {
            border: 1px solid #01BAEF;
            
          }
          .btn-new-project {
            background: linear-gradient(91.81deg, #48DDF2 20.19%, #95E4B3 80.88%);
            color: #232732;
          }
        };
        
        &.DESIGNER:hover {
          .DESIGNER.content {
            border: 1px solid #B71DFF;
            
          }
          .btn-new-project {
            background: linear-gradient(91.81deg, #48DDF2 20.19%, #95E4B3 80.88%);
            color: #232732;
          }
        }

        &.AI {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        
        &.DESIGNER {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

      }
      .content {
        background-color: #161A24;
        border: 1px solid #555555;
        width: 368px;
        height: 180px;
        box-sizing: border-box;
        padding: 20px;

        .description {
          color: #cccccc;
          font-size: 14px;
          height: 22px;
          line-height: 22px;
          margin-top: 8px;
          letter-spacing: -0.05em;
        }

        .btn-new-project {
          background-color: #232732;
          color: #CCCCCC;
          width: 328px;
          height: 50px;
          border-radius: 6px;
          font-size: 18px;
          margin: 20px auto;
          font-weight : 700;
          &:hover {

          }
        }

        &.AI {
          >.title {
            color: #01BAEF;
          }
          >.description {
            color: #cccccc;
          }

       
        }
        &.DESIGNER{
          >.title {
            color: #B71DFF;
          }

        }
        
      }
      .AI.content {
        border-right-width: 0.5px;
        
      }
      .DESIGNER.content {
        border-left-width: 0.5px;
      }

    }


  }

}