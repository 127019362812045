.dropbox {
  box-sizing: border-box;
  background-color: black;
  width: 240px;
  height: 180px;
  position: absolute;
  z-index: 15;
  padding-top: 10px;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: auto 20px;
    cursor: pointer;
    &:hover {
      background-color: #273945;
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
  }
  .dropdown-content {  
    padding-top: 10px;
  }
}