.ProjectList {
  background-color: #1a1e28;
  min-height: calc(100vh - 280px);
  margin-bottom: 100px;
  display: flex;
  flex-flow: column;
}

.copy-modal {
  display: flex;
  flex-flow: column;
  .table-wrapper {
    max-height: 315px;
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    .table {
      .table-head {
        display: flex;
        background-color: #161a24;
        height: 40px;
        top: 0;
        position: sticky;
        z-index: 500;

        .table-row {
          display: flex;
          height: 40px;
          .table-cell {
            display: flex;
            font-family: "Roboto", "Noto Sans KR";
            box-sizing: border-box;
            color: #ccc;
            height: 40px;
            border-bottom: 1px solid #333333;
            font-size: 14px;
          }
        }
      }
      .table-body {
        display: flex;
        background-color: #232732;
        flex-direction: column;
        .table-row {
          display: flex;
          height: 50px;
        }
      }
      .table-cell {
        font-family: "Roboto", "Noto Sans KR";
        box-sizing: border-box;
        padding: 0px 10px;
        height: 50px;
        border-bottom: 1px solid #333333;
        display: flex;

        > div {
          display: flex;
          justify-items: center;
          align-items: center;
          color: #ccc;
        }

        &.no-data {
          width: 660px;
          text-align: center;
          color: #ccc;
          .modal-data {
            display: flex;
            flex-flow: row;
            align-items: center;
          }
        }
        &.checkbox {
          width: 40px;
          padding: 0px;
          align-items: center;
          justify-content: center;
          > .checkbox {
            margin: auto;
            width: 40px;
            color: #ccc;
            &.checked {
              color: #95e4b3;
            }
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &.project-id {
          width: 70px;
          > div {
            width: 70px;
          }
        }
        &.project-type {
          width: 80px;
          align-items: center;
          justify-content: center;
        }
        &.project-type-change {
          width: 120px;
          align-items: center;
          justify-content: center;
        }
        &.project-status {
          width: 50px;
          .icon {
            width: 20px !important;
            height: 20px !important;
            margin-left: 5px;
          }
        }
        &.project-name {
          width: 410px;
          > .head {
            display: flex;
          }
          > .row {
            width: 410px;

            &.bigger {
              width: 500px;
            }
          }
          &.bigger {
            width: 480px;
          }
        }
        &.project-price {
          width: 130px;
          > div {
            width: 130px;
          }
        }
        &.discount-rate {
          width: 70px;
          > div {
            width: 70px;
          }
        }
        &.discount-select {
          width: 100px;
          > div {
            width: 100px;
          }
          .select-switch {
          }
        }
      }
    }
  }

  .info-wrap {
    width: 100%;
    padding: 30px 0px 0px 0px !important;
    display: flex;
    flex-flow: row;
    color: #cccccc;
    font-size: 14px;
    > .info {
      margin: 0 4px;
      padding: 0px;
      display: flex;
      align-items: center;
    }
  }
}
