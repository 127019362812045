.rc-tree-child-tree {
  display: block;
  box-sizing: border-box;
}
// .animation.BlockTreeList .rc-tree-treenode .rc-tree-switcher-noop::before {
.node-motion {
  transition: all .3s;
//  overflow-y: hidden;
}

.animation.BlockTreeList {
  box-sizing: border-box;
  color: #ccc;

  .rc-tree-title {
    height: 30px;

//    position: relative;
    .title {

      display: flex;
      justify-content: space-between;
    }

    .block-number{
      display: inline-block;
      width: 30px;
      height: 30px;
      font-size: 10px;
      text-align: center;
      line-height: 30px;
    }

  }
  .blockIcon {
    stroke: #EEE;
    width: 12px !important;
    height: 12px !important;
    margin-left: 5px;
  }

  .tooltip-wrap {
    margin-top: -1px;
    position: absolute;
    right: 7px;
    cursor: default;
    .disabled {
      cursor: default !important;
      pointer-events: none !important;
      width: 14px !important;
      color: #333 !important;
    }
  }

  
  .btn {
    &.showBlockError {
      width: 30px;
      height: 30px;
      min-width: 30px;
      border-radius: 0;
    }
    background: #1A1E28 !important;
    &:hover {
      background-color: initial;
    }
    &:hover, &.active {
      // background-color: #60626a;
      background-color: #273945 !important;
      color: #eee;
    }
    .icon {
      &.speaker-icon {
        width: 16px !important;
      }
  
    }
  }
  .component {
    .rc-tree-title {
    //  width: 170px;
      
    }
  }

  .rc-tree-treenode {
    display: block;
    // flex-direction: row;
    // justify-content: center;
    font-size: 14px;
    line-height: 30px;
    width: 250px;
    height: 30px;
    margin-left: 20px;
    box-sizing: border-box;
   


    //!!
    &:hover:not(.window):not(.component) {
       background-color: #273945;
    }

    .rc-tree-indent {
      position: relative;
      align-self: stretch;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;

      .rc-tree-indent-unit {
        position: relative;
        height: 100%;

        // &::before {
        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   border-right: 1px solid blue;
        //   left: 50%;
        //   content: '';
        // }

        &-end {
          &::before {
            display: none;
          }
        }

        // End should ignore
        // &-end {
        //   &::before {
        //     display: none;
        //   }
        // }
      }
    }

    // .rc-tree-switcher-noop { // leaf
    //   &::before {
    //     content: '';
    //     display: inline-block;
    //     width: 9px;
    //     height: 9px;

    //     margin-right: 3.5px;
    //     // background: #ccc;
    //     // border-radius: 50%;
    //   }
    // }

    // Motion
    &-motion:not(.node-motion-appear-active) {
      // .rc-tree-indent-unit {
      //   &::before {
      //     display: none;
      //   }
      // }
    }
  }
  .component.rc-tree-treenode, .window.rc-tree-treenode { // 컴포넌트
    box-sizing: border-box;
    .rc-tree-switcher { // C, U블록
      margin-left: 50px;
      margin-right: 2px;
      .icon {

      }
    }

    &:hover {
      border: 1px solid #48DDF2;
    } 
  }

  .window.rc-tree-treenode { // 창문
    .rc-tree-switcher {
      margin-left: 70px;
      margin-right: 2px;
      background-color:transparent;
    }
  }

  .blockB { // 블럭 B
    .rc-tree-switcher {
      margin-left: 26px;
    }
  }
  
  .rc-tree-treenode-selected:not(.window) {    
   // border: 1px solid #48DDF2;
  }
  
  .icon {
    &.MuiSvgIcon-root-27 {
      box-sizing: border-box;
      height: 30px;
    }


  }

  
  
}

    //!
    .rc-tree-node-content-wrapper {
      width: 200px;
      position: unset !important;
    }
    .rc-tree-list{
      height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll ;
    }