header {
  .Head {
  
    width: 100%;
    height: 60px;
    background-color: #232732;
    color: #eeeeee;
    font-size: 20px;

    min-width: 1200px;

    
    .dropdownButton {
      position: relative;
      cursor: pointer;
      width: fit-content;
      margin: auto;
      display: flex;

      .mainText {
        display: flex;
        margin-right: 10px;
        font-weight: 400;
     //   width: 231px;
        box-sizing: border-box;
        height: 60px;
      
        
        padding-left: 10px; 
        // > .typeName {
        //   margin-left: 10px;
        //   font-size: 20px;
        //   // font-family: "Noto Sans KR";
        //   display: flex;
        //   align-items: center;
        // }

        > .name {
          letter-spacing: -0.05em;
          font-size: 18px;
          line-height: 28px;
          display: flex;
          align-items: center;
        }

        > .arrowDown {
          width: 18px;
          height: 18px;
          margin: auto;
          margin-left: 10px;
          margin-right: 10px;
        }

        > .rotate {
          transform: rotate(-180deg);
        }
      }
      


      .dropbox {
        position: absolute;
        display: none;
        width: 240px;
        height: 120px;
        background: #000000;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 10px 0 10px 0;
        z-index: 2;
        
        > .cell {
          height: 40px;
          display: flex;

          > .checkIcon {
            width: 20px;
            height: 20px;
            margin: 10px;
            color: #95e4b3;
          }

          > .hidden {
            visibility: hidden;
          }

          > .name {
            margin: auto 0px auto 0px;
            font-size: 16px;
            line-height: 19px;
          }

          > .typeName {
            font-size: 14px;
            margin: auto 0px auto 5px;
          }
        }
        > .cell:hover {
          background: #555;
        }
      }

      > .show {
        display: block;
      }

      > .dropbox::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #000000 transparent;
      }
    }

    .breadScrum {
      display: flex;
      position: absolute;
      height: 60px;
      align-items: center;
      .logoDiv {
        height: 60px;
        display: flex;
        cursor: pointer;
        > .logoImg {
          height: 25px;
          margin: auto;
          margin-left: 30px;
        }
      }
      .file-setting {
        height: 28px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .home-btn {
        min-width: 22px;
        width: 22px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:  auto;
      }
      .icon {
        &.next-icon {
          width: 24px !important;
          height: 24px !important;
          color: #555;
          &.first {
            margin-left: 10px;
          }
        }
        &.home-icon {
          display: flex;
          justify-content: center;
          margin: 0;
          width: 22px !important;
          height: 22px !important;
        }
      }

  
    }
    

    .HeaderDropBox {
        display: flex;
        justify-content: center;
      
    }
    .middle-wrap {
      display: flex;
      justify-content: center;
      .download-cadastral-map-btn {
        //  color: #EEE;
          width: 140px;
          //font-weight: 700;
          font-family: 'Noto Sans KR' !important;
          &:hover {
            color: #CCC !important;
          }
        }
    }
    > .Tools {
      position: absolute;
      right: 0;
      top: 10px;
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-right: 20px;

      .imageBox {
        cursor: pointer;        
        margin-left: 5px;
        >.icon {
          &.open-icon {
            fill: #ccc;
            width: 16px !important;
          }
        }
        &.hidden {
          display: none;
        }
      }

      > .account {
        width: 40px;
        display: flex;
        position: relative;
        margin: auto;
        > .icon {
          width: 20px;
          height: 20px;
          margin: auto;
        }
      }

      > .errorMessage {
        height: 40px;
        display: flex;
        margin: auto;
        margin-right: 5px;
        .errorInfo {
          display: flex;
          .completeIcon {
            fill: #48DDF2;
            margin-right: 3px;
            &.disabled {
              fill: #555;
            }
            
          }
          .icon {
            &.disabled {
              color: #555;
              fill: #555;
            }

          }

  
          .errorIcon {
            color: #e81c1c;
          }

          .warningIcon {
            color: #ffb068;
          }

          .infoIcon {
            color: #95E4B3;
          }

          .icon {
            &.completeIcon {
              width: 14px !important;

            }
            &.warningIcon{
              width: 15px !important;
            }
            height: 16px !important;
            margin: auto 0px auto 5px;
          }

          .value {
            font-size: 12px;
            margin: auto 2px auto 2px;
          }
        }

        .hidden {
          display: none;
        }
      }

      .tooltip {
        > .tooltiptext {
          position: absolute;
          width: max-content;
          visibility: hidden;
          opacity: 0;
          cursor: default;
          background-color: #000000;
          color: #eeeeee;
          text-align: center;
          padding: 5px 10px;
          font-size: 12px;
          z-index: 2;
          bottom: -100%;
          left: 50%;
          transition: opacity 0.3s;
          transform: translate(-50%, 0%);
        }

        > .tooltiptext::after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #000000 transparent;
        }
      }
      .tooltip:hover {
        > .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }

      .saveButton {
        width: 185px;
        margin-left: 15px;
        font-size: 14px;
        font-family: 'Noto Sans KR' !important;
        &.disabled {
          pointer-events: none;
          // color: #555555;
          // background: #333333;
        }
      }
    }
  }
}
