.dropFileBox {
  position: absolute;
  font-family: Noto Sans KR;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #111A22;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;

  .drag-cover {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    pointer-events: none;
    opacity: 0.3;
    // opacity: 0.5;
  }
  &.hidden {
    display: none;
  }
  
  .icon {
    &.upload-icon {
      width: 18px !important;
    }
    &.download-icon {
      width: 18px !important;
      filter: invert(93%) sepia(1%) saturate(0%) hue-rotate(327deg) brightness(90%) contrast(89%);
    }
  }

  .converter-type {
    background: #161E26;
    border: 1px solid #333;
    width: 1000px;
    height: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  // padding-top: 50px;
    box-sizing: border-box;
    position: relative;

    &.m-r-30px {
      margin-right: 30px;
    }

    >.type-title {
      margin-bottom: 30px;
      font-size: 24px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      line-height: 1.5em;
      .icon-wrap {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .help-icon {
        margin-left: 7px;
      
        width: 17px !important;
      }
    }
   
    .btn {
      width: 320px;
      text-transform: none;
      height: 40px;
    }
    .open-btn-wrap {
      &:hover .btn {
        background-image: linear-gradient(to right, #95e4b3, #48ddf2);
        transition: background-image 1s; 
      }
    }
    .bottom-text {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    >.dark-wrap {
      background-color: #0C0E10;
      opacity: 0.7;
      width: 400px;
      height: 570px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      >.text {
        font-weight: 700;
        font-size: 40px;
      }
    }
  }


  // .content {
  // display:flex;
  // justify-self: center;
  // align-self: center;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // width: 800px;
  // height: 500px;
  // background: #232b33;
  
  //   .button {
  //     width: 360px;
  //     margin: 20px auto;
  //     font-size: 18px;
  //   }

  //   .topText {
  //     font-size: 24px;
  //     color: #cccccc;
  //   }

  //   .bottomText {
  //     font-size: 18px;
  //     color: #cccccc;
  //   }
  //   &.noEvent {
  //     pointer-events: none;
  //   }

  //   &.topMargin40 {
  //     margin-top: 40px;
  //   }
  // }

  // > .file-drop {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   .file-drop-target {
  //     height: 100%;
  //   }
  //   .cover {
  //     width: 100%;
  //     height: 100%;
  //     display: none;
  //   }
  // }
  // .file-drop > .file-drop-target.file-drop-dragging-over-target {
  //   .cover.hidden {
  //     display: block;
  //     position: absolute;
  //     top: 0px;
  //     width: 100%;
  //     height: 100%;
  //     background: #c4c4c4;
  //     pointer-events: none;
  //     opacity: 0.3;
  //   }
  // }

  // &.hidden {
  //   display: none;
  // }

  // .sampleExample {
  //   position: relative;
  //   width: 700px;
  //   height: 218px;
  //   background: #111a22;
  //   display: flex;
  //   margin: auto auto 0 auto;
    
  //   &.hidden {
  //     display: none;
  //   }
  
  //   .sampleImg {
  //     margin: auto;
  //   }
  //   .sampleText {
  //     margin: auto;
  //     a{
  //       text-decoration: none;
  //     }

  //     .title {
  //       color: #eee;
  //       font-size: 26px;
  //       margin-bottom: 12px;
  //       width: fit-content;
  //     }

  //     .content {
  //       width: fit-content;
  //       margin-left: 0px;
  //       color: #ccc;
  //     }

  //     .downloadButton {
  //       width: 260px;
  //       height: 40px;
  //       margin-top: 13px;
  //       display: flex;
  //       background: #383b45;
  //       border-radius: 6px;
  //       color: #eee;
  //       cursor: pointer;
  //       > span {
  //         margin: 10px;
  //       }
  //       .donwloadIcon {
  //         margin: 10px;
  //         margin-left: auto;
  //       }
  //     }
  //   }
  // }
  //   .button-wrap {
  //     display: flex;
  //   }
    
}
