.ProjectSummary {
  .more-btn {
    width: 100px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  > .title {
    font-size: 18px;
    border-bottom: 1px solid #555;
    height: 50px;
    display: flex;
    align-items: center;
  }
  > .content {
    > .project-name-wrap {
      padding-top: 30px;
      > .project-name-title {
        color: #999;
        font-size: 12px;
      }
      > .project-name {
        color: #ccc;
        font-size: 18px;
        padding-top: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
      }
    }
    > .project-explanation-wrap {
      width: 1078px;
      > .title {
        height: 20px;
        font-size: 12px;
        color: #999;
        margin-top: 20px;
      }
      > .project-explanation {
        padding: 10px;
        box-sizing: border-box;
        color: #ccc;
        font-size: 14px;
        background-color: inherit;
        border: 1px solid #333;
        height: 100px;
        width: 100%;
        margin-top: 10px;
        resize: none;
        line-height: 150%;
        &:focus {
          outline: none;
        }
      }
    }
    > .map-wrap {
      height: 470px;
      padding-top: 30px;
      display: flex;
      flex-flow: row;
      border-radius: 6px;
      > .map-content {
        width: 1078px;
        position: relative;
        .map-converter {
          position: absolute;
          left: 20px;
          top: 20px;
          height: 82px;
          &.tabs {
            .tab {
              height: 36px !important;
              font-size: 10px !important;
            }
          }
        }
        > .site-info {
          &.hidden {
            width: 30px;
            padding: 0px;
            > .key-value {
              display: none;
            }
            > .closed {
              > img {
                transform: rotate(180deg);
              }
            }
          }
          width: 230px;
          position: absolute;
          bottom: 20px;
          right: 20px;
          background-color: rgba(22, 26, 36, 0.7);
          padding-top: 20px;
          border-radius: 6px;
          > .key-value {
            display: flex;
            flex-flow: row;
            align-items: center;
            color: #ccc;
            padding-left: 16px;
            padding-right: 16px;
            > .key {
              font-size: 12px;
            }
            > .value {
              margin-left: auto;
              font-size: 14px;
            }
          }
          > .closed {
            height: 30px;
            display: flex;
            align-items: center;
            padding-right: 5px;
            > img {
              margin-left: auto;
              cursor: pointer;
            }
          }
        }
      }
      > .btns-content {
        display: flex;
        flex-flow: column;
        margin-top: auto;
        margin-left: auto;
        .tab {
          width: 100px;
          margin-bottom: 5px;
          font-size: 10px;
          text-align: left;
          padding-left: 6px;
          display: block;
          > span {
            justify-content: unset;
            align-items: center;
            display: flex;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          .inner-icon {
            width: 10px !important;
            height: 10px !important;
            margin-right: 5px;
            &.project-site {
              color: rgb(35, 39, 50);
              &.reverse {
                color: #555;
              }
            }
            &.road {
              color: rgb(90, 121, 165);
            }
            &.vacancy-outside {
              color: rgb(36, 167, 136);
            }
            &.vacancy-inside {
              color: rgb(255, 119, 80);
            }
            &.skyline {
              color: rgb(105, 178, 217);
            }
            &.boundary-site {
              color: rgb(25, 25, 230);
            }
            &.topography {
              color: #FFF;
            }
          }
        }
      }
    }
    > .config-wrap {
      display: flex;
      flex-flow: row;
      border-top: 1px solid #555;
      padding-top: 20px;
      margin-top: 50px;
      box-sizing: border-box;
      > .title {
        margin-right: 122px;
        width: 102px;
        box-sizing: border-box;
        height: 58px;
        border-bottom: 1px solid #555;
        border-right: 1px solid #555;
        font-size: 18px;
        color: #eee;
      }
      > .content-wrap {
        width: 977px;
        display: flex;
        flex-flow: column;

        > .content {
          min-height: 20px;
          border-left: 1px solid #333;
          padding-left: 20px;
          display: flex;
          flex-flow: column;
          padding-top: 20px;
          position: relative;
          &:first-child {
            padding-top: 0px;
          }

          > .type {
            display: flex;
            flex-flow: column;
            &.buildit-type {
            }
            &.my-type {
              > .title {
                display: flex;
                flex-flow: row;
                align-items: center;
                > .more-my-type-btn {
                  margin-left: auto;
                  color: #ccc;
                  font-size: 12px;
                  padding: 0px;
                  .icon {
                    margin-left: 10px;
                    color: #ccc;
                  }
                }
                &:hover {
                  background-color: inherit;
                }
              }
            }
          }
        }
      }
      &.basic {
        > .content-wrap {
          > .content {
            flex-flow: row nowrap;
            > .basic-box {
              width: 224px;
              box-sizing: border-box;
              margin-right: 20px;
              &.address {
                width: 468px;
              }
            }
            > :last-child {
              margin-right: 0px;
            }
            > .textarea {
              width: 712px;
            }
          }
        }
      }
      &.value {
        > .content-wrap {
          > .content {
            flex-flow: row nowrap;
            > .value-box {
              width: 102px;
              box-sizing: border-box;
              margin-right: 20px;
            }
            > :last-child {
              margin-right: 0px;
            }
            .project-value-wrap {
              margin-right: 20px;
              &:last-child {
                margin-right: 0px;
              }
              > .title {
                font-size: 14px;
                border-bottom: 1px solid #555;
                height: 30px;
              }
              > .content {
                display: flex;
                flex-flow: row;
                padding-top: 10px;
                > .value-box {
                  width: 102px;
                  box-sizing: border-box;
                  margin-right: 20px;
                  &.large {
                    width: 223px;
                  }
                  &:last-child {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
      &.housing-type {
        .C3Chart-legend {
          margin-top: auto;
          max-height: 230px;
          margin-bottom: auto;
          box-sizing: border-box;
          overflow-y: auto;
          justify-content: unset;
        }
        > .content-wrap {
          > .content {
            > .housing-chart-wrap {
              width: 712px;
              background-color: #232732;
              border-radius: 6px;
              box-sizing: border-box;
              padding: 20px;
              height: 310px;
              display: flex;
              align-items: center;
            }
          }
          .value-box {
            width: 102px;
            box-sizing: border-box;
            margin-right: 20px;
          }
        }
      }
      &.building-type {
        > .content-wrap {
          > .content {
            flex-flow: column;
            > .type {
              &:last-child {
                margin-top: 30px;
              }
              > .title {
                font-size: 18px;
                color: #ccc;
                margin-bottom: 10px;
                height: 30px;
                > span {
                }
              }
              > .list {
                width: 957px;
                display: flex;
                flex-flow: row wrap;
                > .building-type-box {
                  margin-right: 20px;
                  margin-bottom: 0px;
                  &.no-margin-right {
                    margin-right: 0px;
                  }
                  &.top-margin {
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
