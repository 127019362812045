.MainBody {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  min-width: 1200px;
  // overflow: unset;
  background-color: #232732;

  div input {
    outline: 0px;
  }
    .siteInfo {
    pointer-events: none;
    .fieldText {
      padding: 10px;
      position: absolute;
      z-index: 1;
      transform: translateX(-50%) translateY(-50%);      
      width: max-content;//170px;
      overflow: hidden;
      font-weight: 400;
      color: #fff;
      display: flex;
    }

  }
  .areaInfo {
    pointer-events: none;

    .textTest {
      background-color: #242831;
      opacity: 0.8;
      padding: 10px;
      position: absolute;
      z-index: 1;
      transform: translateX(-50%) translateY(-50%);      
      width: max-content;//170px;
      overflow: hidden;
      border-radius: 0;
      border: none;
    
      .key-value {
        display: flex;
        justify-content: space-between;
        
        li {
          margin: 0;
          list-style: none;
        }
        .key {
          margin: 0 !important;
          font-weight: 700;
          color: #EEE;
          margin-right: 20px;
          &.core {
            width: fit-content;
          }
          &.house {
            width: fit-content;
          }
        }
        .value {
          min-width: 53px;

          margin-left: 3px;
          color: #eee;
        }
  
      }
      
    }
  }

  .information {
    height: 60px;
    border: solid 1px #333333;
    display: flex;
    flex-flow: row;
    align-items: center;
    background-color: #1E222E;;
    .info {
      display: inline-table;
   //   width: 160px;
      height: 60px;
      border-right: solid 1px #333333;
      height: -webkit-fill-available;
      box-sizing: border-box;
      padding: 12px 20px;
    }

    .infoLabel {
   //   color: #999999;
     // font-size: 12px;
    }

    .inforValue {
      margin-top: 4px;
//      color: #cccccc;
   //   font-size: 14px;
    }

    .fileInfo {
      width: 320px;
      height: 60px;
      color: #555555;
      display: flex;
      position: absolute;
      right: 0;
      .dxfIcon {
        width: 24px;
        height: 24px;
        font-size: 10px;
        margin: auto 0 auto 10px;
        border: 2px solid #555;
        border-radius: 50%;
        display: flex;

        &.loaded {
          color: #95e4b3;
          border: 2px solid #95e4b3;
        }

        >.text {
          margin: auto;

          &.loaded {
            color: #95e4b3;
          }
        }
      }

      .fileName {
        position: relative;
        width: 280px;
        margin: auto 0 auto 10px;

        &.loaded {
          color: #ccc;
        }
      }

      .layerToggle {
        position: relative;
        cursor: pointer;
        margin: auto 0 auto auto;
        font-size: 12px;
        width: 64px;
        right: 0px;

        &.loaded {
          color: #48ddf2;
        }
      }
    }
  }

  .RenderView {
    height: calc(100vh - 122px);
  }

  .Scene {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: min-content;
    height: min-content;
    .fold-btn {
      top: 0;
      right: 0;
      min-width: 30px !important;
      width: 30px;
      height: 30px;
      z-index: 2;
      border-radius: 0;;
      position: absolute;
    
    }
    .toolBar {
      position: absolute;
      display: flex;
      flex-flow: row;
      z-index: 2;
      right: 0px;
     // margin-top: 50px;
      margin-right: 10px;

      .rightButtons {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 36px;
      

    
      }

      .switchTabs {
        width: 40px;
        height: 78px;
        &.three-tabs {
          height: 116px;
        }
        //116px;
        display: flex;
        flex-flow: column;
        background-color:#1A1E28;
        ;
        border-radius: 6px;
        margin-bottom: 10px;

        .switchButton {
        // CAD / MAP
          width: 36px;
          height: 36px;
          box-sizing: border-box;
          font-size: 10px;
          padding: 0px;
          margin: 2px;
          margin-bottom: 0px;
          min-width: auto;
          color: #cccccc;

          &.enable {
            background-color: #ccc;
            color: #232732;
          }
        }

        .switchButton:hover {
          background-color: #ccc;
          color: #232732;

        }

        &.hidden {
          display: none;
        }
      }

      .infoButton {
        border-radius: 5px;   
        height: 40px;
        &.active {
          background-color: #1A1E28;
        }
        .icon {
          &.info-icon {
            width: 16px !important;
          }
        }
      }

      .searchDiv {
        position: relative;
        margin-right: 20px;

        .addressSearch {
          width: 360px;
          height: 40px;
          box-sizing: border-box;
          padding: 0 40px 0 10px;
          color: #999999;
          font-size: 14px;
        }

        >.icon {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 0px;
          margin: 10px 10px 10px 0;
        }
      }

      .slider {
        -webkit-appearance: none;
        appearance: none;
        z-index: 1;
        width: 150px;
        background-color: #999999;
        border-radius: 6px;
        margin: 0 10px 0 0;
        height: 3px;
        transform: rotate(270deg) translateX(calc(-50% - 10px)) translateY(5px);
        outline: none;

        &.hidden {
          display: none;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 15px;
          height: 15px;
          background: #60626a;
          border-radius: 50%;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 15px;
          height: 15px;
          background: #60626a;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      &.hidden {
        display: none;
      }
    }

    .Canvas {
      position: relative;
      display: flex;

      canvas {
        position: relative;
        outline: 0px;
      }

      .autosetDiv {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        z-index: 1;

        .autosetButton {
          position: relative;
          margin: auto;
          width: 380px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          border-radius: 6px;
          background: linear-gradient(93.5deg, #66dfd9 51.57%, #95e4b3 81.41%);
        }

        .autosetMessage {
          position: relative;
          width: 360px;
          height: 38px;
          color: #ccc;
          background: #000;
          padding: 6px 10px 6px 10px;
          margin: 10px auto 0 auto;
          font-size: 11px;
        }

        &.hidden {
          display: none;
        }
      }
    }

    .helpIcon {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 30px;
      bottom: 30px;
      z-index: 2;
      color: #ccc;
      cursor: pointer;
    }
  }
  .field-item {
    width: 280px;
    height: 30px;
    border: 1px solid transparent;
    margin: 0 20px;
    display: flex;
    align-items: center;
    color: #ccc;
    .text {
      .block-icon {
        margin: auto 7px;
        &.active {
          background-color: #444A5A;
        }
      }
    }
    .tooltip-wrap {
      cursor: default;
    }
    .btn {
      min-width: 30px !important;
      height: 30px;
      border-radius: 0;
      &.icon-wrap {
        background-color: #1A1E28;}
          }
    &.selected {
      .text {
        .block-icon {
          stroke: #48ddf2;
        }
        background-color: #444A5A;
      }
    }
    >.text {
      display: flex;
      align-items: center;
      height: 30px;
      width: 250px;
      cursor: pointer;

      &:hover {
        background-color: #273945;
//        background-color: #444A5A;
      }
    }

    .icon-wrap {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.disabled {
        pointer-events: none;
//        background-color: #fff !important;
        background-color: #1a1e28 !important;
        cursor: default !important;
        pointer-events: none;
      }
      &.active {
        background-color: #444A5A !important;

      }
      .icon {
        width: 15px !important;
        &.disabled {
          color: #333;
        }
      }
    }
  }

  .mainLayout {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 640px;
    box-sizing: border-box;
    background: #161a24;
    .block-name {
      &:hover {
        background-color: #555;
      }
    }
    >.layerLayout {
      // display: flex;
      flex-flow: column;
      width: 235px;
      >.blockList {
        color: #EEE;
        height: 100%;
        overflow-y: scroll;
        white-space: nowrap;
      }
      
      >.layerList {
        height: calc(100% - 58px);
        overflow: auto;

        button {
          width: 175px;
          height: 30px;
          margin: 1px 30px;
          font-size: 12px;
          color: #eeeeee;
          background-color: #232732;
          border: solid 1px #333333;
          flex: 0 0 auto;

          &.selected {
            background-color: #eee;
            color: #232732;
          }

          &.error {
            background-color: red;
            color: #232732;
          }
        }

        button:hover {
          background-color: #eee;
          color: #232732;
        }

        button:after,
        button:before {
          background-color: #232732;
        }
      }

      >.arrowDiv {
        height: fit-content;
        display: flex;
        align-items: flex-end;
        flex: 0 0 auto;

        >.forwardArrow {
          color: #eeeeee;
          margin: 20px 30px 20px auto;
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  .functionalLayout {
    display: flex;
    flex-direction: column;
    width: 420px;
    flex-basis: auto;
    height: 100%;
    // overflow: auto;

    >.description {

    }
    .fileNameLayout {
      flex: 0 0 auto;
      width: 100%;
      height: 142px;
      display: flex;
      flex-flow: column;

      // padding-left: 20px;
      >.titleText {
        margin-top: 23px;
        margin-left: 20px;
        font-size: 12px;
        color: #999;
        font-family: "Noto Sans KR";
      }

      >input {
        width: 380px;
        height: 40px;
        margin-left: 20px;
        margin-top: 5px;
        box-sizing: border-box;
        padding: 0px 9px;
        background-color: #0c111b;
        border: 0px;
        color: #ccc;
      }

      >.switchTabs {
        width: 380px;
        height: 40px;
        display: flex;
        background-color: #383b45;
        border-radius: 5px;
        margin: 10px 20px;
        font-family: Noto Sans KR;

        .switchButton {
          width: 187px;
          height: 36px;
          padding: 0px;
          margin: 2px;
          border-radius: 6px;
          display: flex;
          color: #cccccc;
          cursor: pointer;

          .text {
            font-size: 12px;
            margin: auto;
          }

          &.enable {
            background-color: #eeeeee;
            color: #232732;
          }
        }

        .switchButton:hover {
          background-color: #eeeeee;
          color: #232732;
        }
      }
    }

    .bodyLayout {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;
      display: flex;
      flex-flow: column;
      padding-bottom: 20px;

      >button {
        flex: 0 0 auto;
        border: 0;
        font-size: 12px;
        width: 175px;
        height: 30px;
        border-radius: 6px;
        color: #cccccc;
        background-color: #383b45;
        margin-left: auto;
        margin-right: 30px;
        // margin-bottom: 30px;
        margin-top: 10px;
      }

      // button:hover {
      //   background-color: #60626a;
      // }
      button:after,
      button:before {
        background-color: #232732;
      }

      .addButton {
        background: #383b45;
        border-radius: 6px;
        margin: 20px;
        width: 380px;
        height: 30px;
        display: flex;
        cursor: pointer;

        >.text {
          margin: auto;
          font-size: 12px;
          color: #ccc;
        }
      }

      .addBuilding {
        width: 360px;
        height: 40px;
        background-color: #232732;
        border: solid 1px #383b45;
        border-radius: 6px;
        font-size: 18px;
        font-family: "Noto Sans KR";
      }
    }

    .switchLayout {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: solid 1px #333333;
      border-top: 0px;
      border-bottom: 0px;

      .switchTabs {
        height: 44px;
        width: 360px;
        display: flex;
        background-color: #383b45;
        border-radius: 5px;
        margin: auto;

        .switchButton {
          width: 177px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          padding: 0px;
          margin: 2px;
          min-width: auto;
          color: #cccccc;

          .icon {
            margin: 0 10px;

            &.enable path {
              fill: #383b45;
            }
          }

          >span {
            justify-content: flex-start;
          }

          &.enable {
            background-color: #eeeeee;
            color: #232732;
          }
        }
      }
    }

    .saveLayout {
      width: 100%;
      height: 100px;
      flex: 0 0 auto;

      >div {
        color: #999999;
        font-size: 12px;
        width: 100%;
      }

      >button {
        border: 0;
        font-family: "Noto Sans KR";
        font-weight: bold;
        font-size: 18px;
        width: 360px;
        height: 40px;
        border-radius: 6px;
        margin-left: 30px;
        margin-top: 20px;
        background-image: linear-gradient(to right, #66dfd9, #95e4b3);

        &.sideMargin {
          margin-top: 10px;
        }

        &.navyButton {
          color: #cccccc;
          background-color: #383b45;
          background-image: none;
          font-weight: normal;
        }
      }

      >button:hover {
        background-image: linear-gradient(to left, #66dfd9, #95e4b3);

        &.navyButton {
          background-color: #60626a;
          background-image: none;
        }
      }

      >button:after,
      >button:before {
        background-image: linear-gradient(to right, #66dfd9, #95e4b3);

        &.navyButton {
          background-color: #383b45;
          background-image: none;
        }
      }

      .extraText {
        box-sizing: border-box;
        padding-left: 30px;
        margin-top: 13px;
        display: flex;

        >.end {
          margin-left: auto;
          margin-right: 30px;
        }
      }

      &.TwoButtonHeight {
        height: 150px;
      }
    }
  }

  ::-webkit-scrollbar {
   width: 5px;

    height: 5px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    width: 12px;
    height: 0;
    background: rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 2.5px;
    background: #999999;
  }
}

// div {
//   &.lbrBorder {
//     box-sizing: border-box;
//     border: solid 1px #333333;
//     border-top: 0px;
//   }
// }

body {
  overflow: hidden;
}

