.ProjectFormBuildingDrawer {
  height: calc(100vh - 60px);
  width: 356px;
  background-color: #1a1e28;
  >.header {
    display: flex;
    flex-flow: column;
    padding: 20px 30px 10px 30px;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
    height: 80px;

    >.title {
      height: 30px;
      color: #eee;
      font-size: 18px;
    }
    >.count {
      font-size: 14px;
      display: flex;
      flex-flow: row;
      align-items: center;
      color: #ccc;
    }
    >.info {
      flex-direction: row;
      color: #ccc;
      font-size: 12px;
    }
    >.close-btn {
      margin-left: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      background: #1a1e28;
      border: none;
      color: #999999;
      font-size: 12px;
      padding: 0px;
      .close-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  >.content {
    padding-bottom: 10px;
    box-sizing: border-box;
    height: calc(100% - 80px);
    overflow-y: auto;
    >.error-section {
      padding-left: 30px;
      padding-top: 10px;
    }
    >.title {
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      color: #999;
    }
    >.empty {
      width: 295px;
      height: 130px;
      display: flex;
      color: #999;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: #232732;
      margin: auto;
      margin-bottom: 10px;
    }
  }
}

.SelectedBuildingType {
  background: #232732;
  // margin-left: 30px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-sizing: border-box;

  &:hover {
    // border-color: #60626a;
  }

  &.vertical {
    width: 224px;
    >.header {
      height: 50px;
    }
    >.img {
      height: 188px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    >.info {
      padding: 0px 16px 20px 10px;
      >.name {
        color: #ccc;
        font-size: 14px;
        margin-bottom: 5px;
      }
      >.key-value {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 12px;
        >.key {
          color: #999;
        }
        >.value {
          margin-left: auto;
          color: #ccc;
        }
      }
    }
  }

  &.horizontal {
    width: 295px;
    margin-left: 30px;
    
    >.header {
      height: 35px;
      // margin-top: 10px;
    }
    >.body {
      display: flex;
      flex-flow: row;
      >.img {
        width: 100px;
        max-width: 100px;                
        height: 90px;
        padding: 2px;
        box-sizing: border-box;
        >img {
          //transform: scale(0.);
          max-width: 120px;
          max-height: 90px;
        }
      }
      >.info {
        width: 180px;
        height: 90px;

        >.name {
          padding: 0px 10px;
          margin-top: 10px;
          margin-bottom: 5px;
          color: #ccc;
          font-size: 14px;
        }
        >.key-value {
          display: flex;
          flex-flow: row;
          align-items: center;
          font-size: 12px;
          padding: 0px 10px;
          >.key {
            color: #999;
          }
          >.value {
            color: #ccc;
            margin-left: auto;
          }
        }
      }
    }
  }

  >.header {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 40px;
    >.title {
      color: #ccc;
    }
    >.close-btn {
      margin-left: auto;
      padding: 0px;
      svg {
        color: #555555;
        width: 20px;
        height: 20px;
      }
    }
  }
  >.body {
    display: flex;
    flex-flow: row;
    height: 90px;
    >.img {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    >.content {

    }
  }
}

.SelectedMyBuildingType {
  background: #232732;
  margin-bottom: 10px;
  border-radius: 6px;
  // margin-left: 30px;
  position: relative;
  box-sizing: border-box;
  &:hover {
    // border-color: #60626a;
  }

  &.vertical {
    width: 224px;
    >.header {
      box-sizing: border-box;
      height: 50px;
      padding: 20px 10px 10px 10px;
      >.title {
        display: flex;
        align-items: center;

        >.id {
          font-size: 14px;
          color: #ccc;
          margin-right: 10px;
        }
      }
    }
    >.img {
      box-sizing: border-box;
      height: 188px;
      text-align: center;
      img {
        width: 188px;
      }
    }
    >.info {
      box-sizing: border-box;
      padding: 10px 10px 20px 10px;
      >.name {
        height: 20px;
        color: #ccc;
        font-size: 14px;
        padding-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }
      >.key-value {
        height: 20px;
        display: flex;
        flex-flow: row;
        font-size: 12px;
        >.key {
          color: #999;
        }
        >.value {
          margin-left: auto;
          color: #ccc;
        }
      }
    }
  }

  &.horizontal {
    width: 295px;
    margin-left: 30px;
    >.header {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      >.title {
        display: flex;
        align-items: center;
        >.id {
          color: #ccc;
          font-size: 14px;
          margin-right: 10px;
        }
      }
      >.close-btn {
        margin-left: auto;
        padding: 0px;
        svg {
          color: #555555;
          width: 20px;
          height: 20px;
        }
      }
    }
    >.body {
      display: flex;
      flex-flow: row;
      >.img {
        width: 115px;
        height: 90px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
        }
      }
      >.info {
        width: 180px;
        height: 90px;

        >.name {
          padding: 0px 10px;
          margin-top: 10px;
          margin-bottom: 5px;
          color: #ccc;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 155px;
        }
        >.key-value {
          display: flex;
          flex-flow: row;
          align-items: center;
          font-size: 12px;
          padding: 0px 10px;
          >.key {
            color: #999;
          }
          >.value {
            color: #ccc;
            margin-left: auto;
          }
        }
      }
    }
  }

  // >.header {
  //   font-size: 12px;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   height: 40px;
  //   display: flex;
  //   flex-flow: row;
  //   align-items: center;
  //   >.title {
  //     color: #ccc;
  //     display: flex;
  //     flex-flow: row;
  //     align-items: center;
  //     span {
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //       width: 160px;
  //     }
  //   }
  //   >.close-btn {
  //     margin-left:  auto;
  //     padding: 0px;
  //     svg {
  //       color: #555555;
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }
  // }
  // >.info {
  //   position: absolute;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   display: flex;
  //   flex-flow: row;
  //   align-items: baseline;
  // }
  // >.img {
  //   height: 180px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   >img {
  //     width: 125px;
  //   }
  // }
}