.SaveModalBody {
  font-size: 12px;
  div {
    // border: 1px solid #333;
    > div {
      width: 600px;
      height: 640px;
      background-color: #1a1e28;
    }
  }

  .modal-title {
    height: 60px;
    color: #999;
    display: flex;
    .title-content {
      margin: auto auto auto 30px;
    }

    > .icon {
      width: 20px;
      height: 20px;
      margin: auto 30px auto auto;
      color: #555;
      cursor: pointer;
    }
  }

  .modal-content {
    height: 500px;
    .SaveCanvas {
      width: 600px;
      height: 500px;
      outline: none;
      > canvas {
        outline: none;
      }
    }
  }

  .modal-action {
    margin: 5px auto;
    width: 548px;
    height: 40px;
    display: flex;
    color: #ccc;

    > .action-content {
      margin: auto 0px;
      margin-right: auto;
    }
    > .modal-btn {
      width: 100px;
      height: 30px;
      margin: 5px;
      display: flex;
      border-radius: 6px;
      cursor: pointer;
      > .btn-text {
        margin: auto;
      }

      &.positive {
        background: #383b45;
      }

      &.negative {
        color: #999;
        background: #232732;
      }
    }
  }
}
