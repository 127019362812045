.field,
.house,
.core {
  margin: 20px;
  width: 380px;
  color: #ccc;//#eeeeee;

  > .nameDiv {
    display: flex;
    flex-flow: row;
    font-size: 18px;
    color: #48ddf2;
    align-items: center;
    margin-bottom: 10px;

    > .closeIcon {
      margin-left: auto;
      width: 20px;
      height: 20px;
      color: #555555;
      cursor: pointer;
    }

    > .showIcon {
      width: 18px;
      height: 18px;
      color: #999;
    }

    > .iconRight {
      margin-right: 5px;
    }

    > .notice {
      margin-left: 10px;
      font-size: 12px;
      color: #f37b7b;
      font-family: "Noto Sans KR";
    }
  }

  .buttonDiv {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ccc;

    .selectButton {
      width: 155px;
      height: 20px;
      border-radius: 6px;
      padding: 10px;
      font-size: 14px;
      display: flex;
      cursor: pointer;
      background-color: #232732;

      &.selected {
        background-color: #60626a;
      }

      .CheckIcon {
        color: #555555;
        width: 20px;
        height: 20px;
        margin-left: auto;

        &.checked {
          color: #95e4b3;
        }
      }
    }

    > .selectButton:hover {
      background-color: #60626a;
    }

    > .houseList {
      display: flex;
      flex-flow: column;
      >.cell{
        display: flex;
        transform: translate(-40px,0);
      }
      .CheckIcon {
        position: relative;
        margin: 10px;
        width: 20px;
        height: 20px;
        color: #95e4b3;
        pointer-events: none;
      }
    }

    &.alignTop {
      align-items: unset;
    }

    .nameText {
      width: 125px;
      height: 20px;
      padding: 10px;
      display: flex;
      > .closeIcon {
        width: 16px;
        height: 16px;
        color: #555;
        display: none;
        margin-left: auto;
        transform: translate(40px,0);
        cursor: pointer;
        &.selected {
          display: block;
        }
        &.hide{
          
        }
      }
    }
  }

  .inputValues {
    display: flex;

    &.hidden {
      display: none;
    }
  }
  .border {
    width: 380px;
    height: 0px;
    margin-bottom: 20px;
    border: 1px solid #333333;
  }

  .extraMenu {
    position: absolute;
    margin-right: 10px;
    right: 0;
    font-size: 12px;
    color: #cccccc;
    text-align: end;
    cursor: pointer;
  }

  > .Line {
    width: 100%;
    height: 1px;
    margin: 10px 0px;
    background: #555555;
  }
}
