.ProjectFormHousing {
  position: relative;
  height: calc(100vh - 127px - 120px);
  box-sizing: border-box;
  margin-left: 30px;
  padding-top: 20px;
  .title {
    width: 98px;
    height: 26px;
    margin-bottom: 12px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: #EEEEEE;
  }
  >.area-type {
    >.area-type-select {
      width: 175px;
      height: 40px;
      box-sizing: border-box;
      background-color: #0c111b;
      color: #ccc;
      font-size: 14px;
      .area-type-option {
        width: 175px;
        background-color: #0c111b;
        color: #ccc;
        padding:10px;
      }

      >div {
        >select {
          width: 175px;
          box-sizing: border-box;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 10px;
          padding-right: 10px;
        }
        >div {
          padding: 0px;
        }
      }
      svg {
        color: #ccc;
        width: 20px;
        height: 20px;
      }
    }
  }
  // >.info {
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  // }
  >.available-list {
    margin-left: -30px;
    padding-left: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-flow: column;
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
    >.info {
      color: #ccc;
      font-size: 12px;
      margin-bottom: 20px;
    }
    >.header {
      color: #48ddf2;
      margin-bottom: 10px;
    }
    >.item {
      display: flex;
      flex-flow: row;
      align-items: center;
      color: #ccc;
      &.font-highlight {
        color: red;
      }
      >.name {
        width: 150px;
      }
      >.variance {
      }
    }
  }
  >.content {
    .errormsg {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.05em;

      color: #E81C1C;
    }
    >.sub-title {
      height: 20px;
      font-size: 14px;
      color: #999;
      margin-bottom: 10px;
    }
    >.housing-type-add-btn {
      width: 115px;
      margin-bottom: 30px;
    }
    >.housing-item {
      // padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #333333;
      margin-bottom: 20px;
      >.header {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: 10px;
        >.title {
        }
        .close-icon {
          color: #555555;
        }
      }
      >.content {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-around;
        justify-content: space-between;
        
        >.proportion {
          width: 175px;
          margin-left: 10px;
        }
        >.housing-select {
          width: 50%;
          &.area {
            width: 175px;
            &.DESIGNER, &.SITE_PLAN {
              width: 100%;
            }
          }
          &.bay {
            width: 95px;
            margin-left: 10px;
          }
          &.full-width {
            width: 100% !important;
          }
        }

        .housing-input {
          margin-top: 10px;
          background-color: #0c111b;
          height: 40px;
          width: 175px;
          box-sizing: border-box;
          color: #ccc;
          >input {
            text-align: right;
          }
        }
      }
    }
  }
  >.content {
    color: #ccc;
    width: 360px;
    .housing-plan-type {
      &.my-type { 
        background-color: #232732;
        margin-bottom: 20px;
        border-radius: 6px;
        >.header {
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          height: 40px;
          font-size: 14px;
        }
        >.body {
          display: flex;
          >.img {
            width: 165px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 125px;
              padding-bottom: 10px;
            }
          }
          >.info {
            width: 195px;
            height: 135px; 
            padding: 10px;
            box-sizing: border-box;
            >.title {
              height: 20px;
              margin-bottom: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              color: #ccc;
            }
            >.key-value {
              display: flex;
              font-size: 12px;
              height: 20px;
              >.key {
                color: #999;
              }
              >.value {
                color: #ccc;
                margin-left: auto;
              }
            }
            >.my-type-proportion {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  >.chart {
    
  }
  
}