.DownloadButton {
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 0px 10px !important;
  font-weight: bold !important;
  .icon {
    margin-left: auto;
  }
  .progress-icon {
    width: 15px !important;
    height: 15px !important;
    padding: 2.5px;
  }
  .download-icon {    
    width: 18px !important;
    height: 18px !important;
  }

}