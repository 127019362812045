.HeaderDropBox {
  &.open .dropbox{
    display: block;
  }
  .text-deco-none {
    text-decoration: none;
    color: inherit;
  }
  .icon.home-icon {
    margin-left: 7px;
    color: #ccc;
    width: 18px !important;
  }
  >.title-wrap {
    display: flex;
    width: max-content;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px;
    &:hover {
      background-color: #273945;
    }
    justify-content: center;
    align-items: center;
    height: 60px;
    .title {
      letter-spacing: -0.05em;
      font-size: 20px;
      font-weight: 400;
    }

    .icon-wrap {
      width: 24px;
      height: 24px;
      .icon {
        margin-left: 7px;
        width: 18px !important;
        height: 20px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2px;
    }
    .mainText {
      display: flex;
      align-items: center;
      justify-items: center;

      .icon {
        display: flex;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
      }
    }
  }

  .dropbox {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 10px 0 10px 0;
    z-index: 1000;
    flex-direction: column;
    justify-content: none;
    align-items: flex-start;

    .checkIcon {
      width: 17px !important; 
      height: 20px;
      margin-right: 14px;
      margin-left: 14px;
    }

    .name-wrap {
      width: 240px;
      display: flex;
      align-items: center;
    }
  }



  .dropdown {
    position: relative;
  }


  .dropdown .dropbtn {
    background-color: inherit;
    margin: 0;
    display: flex;
  }


  .dropdown-content {
    display: none;
    position: absolute;
    top: 60px;
    padding-top: 10px;
    background-color: transparent;
    &.show {
      display: block;
    }
  }

  .select-menu {
    width: 240px;
    display: flex;
    flex-flow: column;
  }
  
  .dropdown:hover .dropdown-content{
    display: block;
  }
}