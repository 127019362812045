.BlockColorInfo {
  .site-marker-info {
    background: rgba(12, 17, 27, 0.9);
    position: absolute;
    top: 142px;
    left: 20px;
    z-index: 100;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 119px;
    ul {
      list-style: none;

      margin: 0;
      padding: 0;
    }
    .title {
      font-size: 10px;
      height: 24px;

    }
    .field-marker {
      cursor: pointer;
      height: 24px;
      display: flex;
      align-items: center;
      .line {
        height: 2px;
        width: 20px;
      }
      &.vacancy-inside {
        .line {
          background-color: #8800C8;
        }
        color: #8800C8;
      }
      &.site {
        .line {
          background-color: #FF3333;
        }
        color: #FF3333;
      }
      &.center-of-road {
        .line {
          background-color: #1045FF;
        }
        color: #1045FF;
      }
      &.road {
        .line {
          background-color: #555555;
        }
        color: #555555;
      }
      &.vacancy-outside {
        .line {
          background-color: #0F6B00;
        }
        color: #0F6B00;
      }
      &.elevation {
        .line {
          background-color: #898989;
        }
        color: #898989;
      }
    }
  
  }

}