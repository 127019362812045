.CadConverterHome {
  .btn-secondary {
    &:hover {
      background: #273945 !important;
      color: #ccc !important;
    }
  }
  
  .mouse-tooltip {
    background: black;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
    height: 27px;
    color: white;
    position: absolute;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.05em;
    &.display-none {
      display: none;
    }

  }

  >.app-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    >.logo {
      position: absolute;
      left: 30px;
    }
  }

  >.content-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 60px);

    >.button-wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      .using-explain-wrap {
        position: relative;
        .cad-converter-guide {
          box-sizing: border-box;
          width: 800px;
          height: 440px;
          background-color: #161A24;
          padding: 30px 45px;
          position: absolute;
          top: 40px;
          left: 0;
          z-index: 15;
          &.hidden {
            display: none;
          }
          .article-wrap {
            >.title {
              display: flex;
              font-weight: 700;
              height: 20px;
              >.title-icon {
                width: 14px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            >.content {
              margin-left: 19px;
              li {
                margin-left: 5px;             
              }
              li::marker {
              }
            }
          }
        }
      }
      .btn {
        width: 200px;
        &.using-explain {
          display: flex;
          justify-content: flex-start;
          border-radius: 0;
          .icon-wrap {
            display: inline-block;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .icon {
          &.pageView-icon {
            margin-left: 7px;
            width: 15px !important;
          }
          &.open-icon {
            margin-left: 7px;
            width: 16px !important;
          }
        }
      }
      .width-590px {
        width: 590px;
      }

      .sample-file-downloads-wrap {
        height: 40px;
      
        .sample-download-btns {
          display: flex;
          justify-content: center;
          position: relative;
          .icon {
            &.download-icon {
              fill: #ccc;
              width: 16px !important;
              margin-left: 8px;
            }
          
          }
          >.btn-left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            
          }

          >.vertical-line {
            width: 1px;
            height: 24px;
            position: absolute;
            right: 40px;
            top: 8px;
            background-color : #555;
            z-index: 100;
          }

          >.btn-right {
            width: 40px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      .dropbtn {
        position: relative;
      }
      .dropdown-content {

        padding-top: 10px;
//        background-color: #fff;
      }

      .sample-file-downloads {
        background-color: black;
        width: 240px;
        height: 180px;
        position: absolute;
        z-index: 15;

        .item {
          padding-left: 20px;
          padding-right: 20px;
          justify-content: space-between;

          .icon {
            &.download-icon {
              fill: #ccc;
              width: 16px !important;
            }
          
          }
        }
      }
    }

    .converter-type-wrap {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .button-wrap {
        display: flex;
      }
      .drag-cover {
        position: absolute;
        top: 0;
        width: 400px;
        height: 580px;
        &.my-type {
          background: #53585E;
          opacity: 0.2;
          }
        z-index: 100;
      }
      .converter-type {
        position: relative;
        background: #161E26;
        border: 1px solid #333;
        width: 400px;
        height: 580px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 50px 40px;
        box-sizing: border-box;
        position: relative;
  
        .thumb-img {
          cursor: pointer;
        }

        &.m-r-30px {
          margin-right: 30px;
        }
        
        >.type-title {
          margin-bottom: 40px;
          font-size: 24px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-wrap {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .help-icon {
            margin-left: 7px;
          
            width: 17px !important;
          }
        }
        .btn {
          width: 320px;
          text-transform: none;
          height: 40px;
          .icon {
            &.upload-icon {
              width: 18px !important;
              fill: #232732;
            }
          }
        }

        .bottom-text {
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >.dark-wrap {
          background-color: #0C0E10;
          opacity: 0.7;
          width: 398px;
          height: 578px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          >.text {
            font-weight: 700;
            font-size: 40px;
          }
        }
      }
    }
  }
  .dropdown:hover .dropdown-content {
   // display: block;
  }
  .dropbtn:hover .dropdown-content{
    display: block;
  } 
}