.rc-tooltip {
  z-index: 10000 !important;
  pointer-events: none;
  > .rc-tooltip-content {
    background: transparent;

    > .rc-tooltip-arrow {
      border-color: transparent;
      > .rc-tooltip-arrow-inner {
        //order-bottom-color: black;
        &.top {
          border-top-color: black;
        }
        &.bottom {
          border-bottom-color: black;
        }
        &.right {
          border-right-color: black;
        }
        &.left {
          border-left-color: black;
        }
      }
    }
    > .rc-tooltip-inner {
      min-height: 0px;
      background-color: black;
      color: #ccc;
      border: 0px;
      background: black;
    }
  }
}

.tooltip-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Tooltip {
  display: inline-flex;
}
